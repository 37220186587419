export const cpfMask = (value:string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const handleFirstName = (value:string) => {
  const firstName : string[] = value ? value.split(' ') : [];
  return value ? firstName[0] : '';
}