import { useState, useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Button,
  TextField,
  IconButton,
  FormControl,
  Table,
  Box,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  TableContainer,
} from "@mui/material";
import School from "@mui/icons-material/School";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { CustomPagination } from "./CustomPagination";

import MaintenanceClassForm from "../MaintenanceStudentForm";
import DeleteClassForm from "../DeleteStudentForm";
import BasicModal from "../../../../components/ModalManagement/BasicModal";
import { IStudents } from "../../types";
import { serializeStudent } from "../../utils/serializeStudent";

export interface CardRowData {
  id: string;
  name: string;
  email: string;
  accessSystems: Array<string>;
  profiles: any;
  inclusionDate: string;
  status: boolean;
  statusAction: (user: any) => Promise<any>;
  editAction: (user: any) => Promise<any>;
  deleteAction: (user: any) => Promise<any>;
}

export interface CardRowDataProfile {
  id: string;
  name: string;
  accessSystems: Array<string>;
  profiles: Array<string>;
  inclusionDate: string;
  status?: boolean;
  statusAction?: (profile: any) => Promise<any>;
  editAction: (profile: any) => Promise<any>;
  deleteAction: (profile: any) => Promise<any>;
}

export function TableManageStudents() {
  const rowsPerPage = 8;
  const [filterList, setFilterList] = useState<string[]>([]);

  const [data, setData] = useState();
  const [students, setStudents] = useState<Array<IStudents>>([
    {
      birthDate: "13/04/2007",
      codRegistration: "1234567",
      createdAt: "13:36 29/07/2022",
      genre: "Feminino",
      nameClass: "9º C",
      nameMother: "Cristina Silveira dos Santos",
      nameStudent: "Helena Silveira dos Santos",
      rg: "3344179-3",
      teachingMode: "Integral",
    },
  ]);
  const [openModalMaintenance, setOpenModalMaintenance] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [actionTitle, setActionTitle] = useState<string>("Adicionar turma");
  const [studentInAction, setStudentInAction] = useState<IStudents>(
    {} as IStudents
  );
  const [page, setPage] = useState(1);
  const [pageFilterUser, setPageFilterUser] = useState("");
  const [pageFilterProfile, setPageFilterProfile] =
    useState("Todos os sistemas");

  const [pageCount, setPageCount] = useState(Math.ceil(8 / rowsPerPage));

  const handleSearch: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = ({ target }) => {};

  const handleClickAddStudent = () => {
    setOpenModalMaintenance(true);
    setActionTitle("Adicionar turma");
  };
  const handleClickEditStudent = (studentData: IStudents) => {
    console.log(serializeStudent.toFormDate(studentInAction));
    setStudentInAction(studentData);
    setActionTitle("Editar turma");
    setOpenModalMaintenance(true);
  };
  const handleSubmitMaintenanceForm = (data: IStudents) => {
    const foundIndex = students.findIndex(
      (s) => s.codRegistration === data.codRegistration
    );
    setStudents((current) => {
      let newStudents = [...current];
      if (foundIndex === -1) newStudents.push({ ...data });
      else newStudents[foundIndex] = data;
      console.log(foundIndex);

      return newStudents;
    });
    setOpenModalMaintenance(false);
  };

  const handleClickDeleteStudent = (studentData: IStudents) => {
    setStudentInAction(studentData);
    setOpenModalDelete(true);
    setActionTitle(
      `Tem certeza que deseja excluir o(a) aluno(a) ${studentData.nameStudent}?`
    );
  };
  const handleDeleteClass = (studentData: IStudents) => {
    const foundIndex = students.findIndex(
      (s) => s.codRegistration === studentData.codRegistration
    );
    if (foundIndex === -1) return;
    setStudents((current) => {
      let newStudent = [...current];
      newStudent.splice(foundIndex, 1);
      return newStudent;
    });
    setOpenModalDelete(false);
  };
  useEffect(() => {
    if (openModalMaintenance || openModalDelete) return;
    setStudentInAction({} as IStudents);
  }, [openModalMaintenance, openModalDelete]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "644px",
          padding: "24px",
          borderRadius: "4px",
          background: "#fff",
          paddingX: "16px",
          width: `calc(100vw - 4vw)`,
          "@media (min-width: 768px)": {
            width: "auto",
            marginX: "5%",
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "32px",
              width: "100%",
              "@media (max-width: 768px)": {
                flexDirection: "column",
                gap: "16px",
                marginBottom: "24px",
              },
            }}
          >
            <Box sx={{ display: "flex" }}>
              <School
                style={{
                  color: "#083CA6",
                  background: "#F2F5FD",
                  width: "48px",
                  height: "48px",
                  padding: "12px",
                  borderRadius: "50%",
                  marginRight: "16px",
                }}
              />
              <Typography
                style={{
                  fontSize: "2rem",
                  color: "#083CA6",
                }}
              >
                Alunos
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                justifyContent: "space-between",
                "@media (max-width: 768px)": {
                  width: "100%",
                  flexDirection: "column",
                },
              }}
            >
              <FormControl
                sx={{
                  "@media (min-width: 768px)": { width: "350px" },
                  width: "100%",
                }}
                size="small"
              >
                <TextField
                  value={pageFilterUser}
                  onChange={(event) => setPageFilterUser(event.target.value)}
                  label="Pesquise por matrícula, nome, RG ou turma"
                  inputProps={{ style: { height: "7px" } }}
                  sx={{ '& label[data-shrink="false"]': { top: "-7px" } }}
                />
              </FormControl>

              <Button
                variant="contained"
                disableElevation
                startIcon={<AddIcon />}
                style={{
                  backgroundColor: "#083CA6",
                  textTransform: "none",
                  fontWeight: "700",
                }}
                className=""
                onClick={handleClickAddStudent}
              >
                Adicionar aluno
              </Button>
            </Box>
          </Box>
          <TableContainer className="mb-[24px]">
            <Table
              sx={{
                tableLayout: "fixed",
                border: "2px solid #F7F8FA",
                width: "1200px",
                "@media (min-width: 768px)": {
                  width: "100%",
                },
              }}
              padding="none"
            >
              <TableHead>
                <TableRow style={{ height: "48px", background: "#F7F8FA" }}>
                  <TableCell
                    sx={{ padding: "0 12px", fontWeight: 600 }}
                    width="10%"
                    align="left"
                  >
                    Matrícula
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0 12px", fontWeight: 600 }}
                    width="10%"
                    align="left"
                  >
                    Nome do aluno
                  </TableCell>

                  <TableCell
                    sx={{ padding: "0 12px", fontWeight: 600 }}
                    width="10%"
                    align="left"
                  >
                    Data de nasciemnto
                  </TableCell>

                  <TableCell
                    sx={{ padding: "0 12px", fontWeight: 600 }}
                    width="10%"
                    align="left"
                  >
                    Modo de ensino
                  </TableCell>

                  <TableCell
                    sx={{ padding: "0 12px", fontWeight: 600 }}
                    width="10%"
                    align="left"
                  >
                    RG
                  </TableCell>

                  <TableCell
                    sx={{ padding: "0 12px", fontWeight: 600 }}
                    width="10%"
                    align="left"
                  >
                    Data de inclusão
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0 12px", fontWeight: 600 }}
                    width="12%"
                    align="center"
                  >
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student) => (
                  <TableRow key={student.codRegistration}>
                    <TableCell
                      sx={{ padding: "0 12px" }}
                      className="truncate ..."
                    >
                      {student.codRegistration}
                    </TableCell>

                    <TableCell
                      sx={{ padding: "0 12px" }}
                      className="truncate ..."
                    >
                      {student.nameStudent}
                    </TableCell>

                    <TableCell
                      sx={{ padding: "0 12px" }}
                      className="truncate ..."
                    >
                      {student.birthDate}
                    </TableCell>

                    <TableCell
                      sx={{ padding: "0 12px" }}
                      className="truncate ..."
                    >
                      Integral
                    </TableCell>

                    <TableCell
                      sx={{ padding: "0 12px" }}
                      className="truncate ..."
                    >
                      {student.rg}
                    </TableCell>

                    <TableCell
                      sx={{ padding: "0 12px" }}
                      className="truncate ..."
                    >
                      {student.createdAt}
                    </TableCell>
                    <TableCell sx={{ padding: "0 12px" }} align="right">
                      <FormControlLabel
                        control={
                          <IconButton
                            size="small"
                            aria-label="Ver"
                            onClick={() => "ok"}
                            disableRipple
                          >
                            <VisibilityIcon sx={{ m: 1 }} />
                          </IconButton>
                        }
                        label={""}
                      />
                      <FormControlLabel
                        control={
                          <IconButton
                            size="small"
                            aria-label="Editar"
                            onClick={() => handleClickEditStudent(student)}
                            disableRipple
                          >
                            <EditIcon sx={{ m: 1 }} />
                          </IconButton>
                        }
                        label={""}
                      />
                      <FormControlLabel
                        control={
                          <IconButton
                            size="small"
                            aria-label="Excluir"
                            onClick={() => handleClickDeleteStudent(student)}
                            disableRipple
                          >
                            <DeleteIcon color="error" sx={{ m: 1 }} />
                          </IconButton>
                        }
                        label={""}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display="flex" justifyContent="right">
          <CustomPagination
            variant="outlined"
            shape="rounded"
            count={pageCount}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        </Box>
      </Box>
      <BasicModal
        title={actionTitle}
        open={openModalMaintenance}
        close={setOpenModalMaintenance}
      >
        <MaintenanceClassForm
          confirmButtonLabel={actionTitle}
          data={studentInAction}
          onConfirm={handleSubmitMaintenanceForm}
          onCancel={() => setOpenModalMaintenance(false)}
        />
      </BasicModal>
      <BasicModal
        title={actionTitle}
        open={openModalDelete}
        close={setOpenModalDelete}
      >
        <DeleteClassForm
          data={studentInAction}
          onConfirm={handleDeleteClass}
          onCancel={() => setOpenModalDelete(false)}
        />
      </BasicModal>
    </>
  );
}
