import { useState, useEffect } from 'react'

import FormControlLabel from '@mui/material/FormControlLabel'

import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import GroupIcon from '@mui/icons-material/Group'
import PersonAddIcon from '@mui/icons-material/PersonAdd'

import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'

import { CustomSwitch } from './CustomSwitch'
import { CustomPagination } from './CustomPagination'

import {
  addStudent,
  deactivateAccount,
  getUsersDataTabbed,
} from '../../../../api/user'

import {
  editProfileAction,
  deleteProfileAction,
  statusUserAction,
  editUserAction,
  deleteUserAction,
} from '../../'

import { LinkUser } from '../../Users/LinkUser'

export interface CardRowData {
  id: string
  name: string
  email: string
  accessSystems: Array<string>
  profiles: any
  inclusionDate: string
  status: boolean
  statusAction: (user: any) => Promise<any>
  editAction: (user: any) => Promise<any>
  deleteAction: (user: any) => Promise<any>
}

export interface CardRowDataProfile {
  id: string
  name: string
  accessSystems: Array<string>
  profiles: Array<string>
  inclusionDate: string
  status?: boolean
  statusAction?: (profile: any) => Promise<any>
  editAction: (profile: any) => Promise<any>
  deleteAction: (profile: any) => Promise<any>
}

type CardManagementProps =
  | {
      profile: false
      rowsPerPage?: number
      buttonAction: () => Promise<CardRowData | null> | any
      data: Array<CardRowData>
    }
  | {
      profile: true
      rowsPerPage?: number
      buttonAction: () => Promise<CardRowDataProfile | null> | any
      data: Array<CardRowDataProfile>
    }

export function CardManagement(props: CardManagementProps) {
  const rowsPerPage = props.rowsPerPage || 10
  const [filterList, setFilterList] = useState<string[]>([])

  const [data, setData] = useState(props.data)
  const [currentData, setCurrentData] = useState(data)
  const [rowData, setRowData] = useState(data.slice(0, rowsPerPage))

  const [page, setPage] = useState(1)
  const [pageFilterUser, setPageFilterUser] = useState('')
  const [pageFilterProfile, setPageFilterProfile] =
    useState('Todos os sistemas')

  const [pageCount, setPageCount] = useState(
    Math.ceil(data.length / rowsPerPage)
  )

  /* Functions Custom */
  const filterData = (
    dataList: Array<CardRowData | CardRowDataProfile>,
    filter: string,
    isProfile: boolean
  ) => {
    if (isProfile) {
      if (filter === 'Todos os sistemas') {
        return dataList
      } else {
        return dataList.filter((row) => row.accessSystems.includes(filter))
      }
    } else {
      if (filter === '') {
        return dataList
      } else {
        return dataList.filter((row) => {
          return (
            row.name.toUpperCase().includes(filter.toUpperCase()) || row.id.includes(filter))
        })
      }
    }
  }

  const createRowData = (
    dataList: Array<CardRowData | CardRowDataProfile>,
    currentPage: number
  ) => {
    const filter = props.profile ? pageFilterProfile : pageFilterUser
    const filteredData = filterData(dataList, filter, props.profile)

    return filteredData.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    )
  }

  /* Handle Functions */
  const handleButtonAction = async () => {
    const data: any = await props.buttonAction()

    const newRow = data;

    if (newRow) {
      if (props.profile) {
        if (newRow.changed) {
          const rawProfileData = newRow.data.data

          const profileData: CardRowDataProfile = {
            id: rawProfileData.id_perfil,
            name: rawProfileData.descricao_perfil,
            accessSystems: ['SAP'],
            profiles: [],
            inclusionDate: '01/01/2022',
            editAction: editProfileAction,
            deleteAction: deleteProfileAction,
          }

          const newData = [...currentData]
          newData.push(profileData)

          setData(newData)
          setCurrentData(newData)
        }
      } else {
        if (newRow.changed) {
          const rawUserData = newRow.data

          let userData: CardRowData = {
            id: rawUserData.cpf,
            name: rawUserData.username,
            email: rawUserData.email,
            profiles: [],
            accessSystems: ['SAP'],
            inclusionDate: '01/01/2022',
            status: true,
            statusAction: statusUserAction,
            editAction: editUserAction,
            deleteAction: deleteUserAction,
          }

          const newData = [...currentData]
          newData.push(userData)
          
          const studentData: any = await LinkUser(
            rawUserData.username,
            rawUserData.cpf
          )

          if (studentData.changed) {
            addStudent(studentData.data);
          }

          setData(newData)
          setCurrentData(newData)
        }
      }
    }
  }

  const handleSwitch = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: CardRowData | CardRowDataProfile
  ) => {
    if (row.status !== undefined && row.statusAction !== undefined) {
      const newData = [...data]
      const newStatus = event.target.checked

      const result = await row.statusAction(row)

      const index = newData.findIndex((el) => el.id === row.id)
      newData[index].status = result ? newStatus : !newStatus

      await setStatus(row.id, newStatus)

      setData(newData)
      setCurrentData(newData)
    }
  }

  const handleEdit = async (row: CardRowData | CardRowDataProfile) => {
    const newData = [...data]
    const newRow = await row.editAction(row)

    if (newRow) {
      const index = newData.findIndex((el) => el.id === row.id)
      if (props.profile) {
        newData[index].name = newRow.data.descricao_perfil
      } else {
        newData[index].profiles = newRow.data.perfil
      }
    }

    setData(newData)
    setCurrentData(newData)
  }

  const handleDelete = async (row: CardRowData | CardRowDataProfile) => {
    const result = await row.deleteAction(row)

    if (result) {
      const newData = [...data]

      const index = newData.findIndex((el) => el.id === row.id)
      newData.splice(index, 1)

      setData(newData)
      setCurrentData(newData)
    }
  }

  const setStatus = async (cpf: string, active: boolean) => {
    const response = await deactivateAccount(cpf, active)
    return response
  }
  
  const defaultUser = {
    id: 0,
    name: 'defaultUser',
    email: 'defaultUser@email.com',
    accessSystems: ['SAP'],
    profiles: [''],
    inclusionDate: '',
    status: false,
    statusAction: statusUserAction,
    editAction: editUserAction,
    deleteAction: deleteUserAction,
  }



  const changePage = (page: number) => {
    getUsersDataTabbed(10, (page - 1) * 10).then((userData) => {
      let currentData: any = Array(userData.count - 10).fill(null).map((_, i) => {return data[i] ? data[i] : defaultUser})
      const newUsers : [] = userData.results.map((user: any) => {
        return {
          id: user.cpf,
          name: user.nome_usuario,
          email: user.email,
          accessSystems: ['SAP'],
          profiles: user.perfil,
          inclusionDate: user.data_criacao,
          status: user.active,
          statusAction: statusUserAction,
          editAction: editUserAction,
          deleteAction: deleteUserAction,
        }
      })
      
      currentData.splice((page-1)*10, 0, ...newUsers)

      
      setData(currentData)
      setCurrentData(currentData)

      setPage(page)
    })
  }

  /* Props Use Effect */
  useEffect(() => {
    setData(props.data)
    setCurrentData(props.data)
  }, [props.data])

  /* Pagination Use Effects */
  useEffect(() => {
    const filter = props.profile ? pageFilterProfile : pageFilterUser
    const filteredData = filterData(data, filter, props.profile)

    setPage(1)
    setPageCount(Math.ceil(filteredData.length / rowsPerPage))
    setCurrentData(filteredData)

    setRowData(createRowData(filteredData, page))
  }, [pageFilterProfile, pageFilterUser])

  useEffect(() => {
    setPageFilterUser('')
    setPageFilterProfile('Todos os sistemas')
  }, [filterList])

  useEffect(() => {
    setRowData(createRowData(currentData, page))
  }, [page])

  useEffect(() => {
    const newFilterList = new Set<string>()

    data.forEach((row) => {
      row?.accessSystems.forEach((system) => {
        newFilterList.add(system)
      })
    })

    setFilterList(Array.from(newFilterList))
  }, [data])

  useEffect(() => {
    const filter = props.profile ? pageFilterProfile : pageFilterUser
    const filteredData = filterData(currentData, filter, props.profile)

    const newPageCount = Math.ceil((123909) / rowsPerPage)

    setRowData(createRowData(currentData, page))
    setPageCount(newPageCount)
  }, [currentData])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '644px',
        padding: '24px',
        borderRadius: '4px',
        background: '#fff',
        paddingX: '16px',
        width: `calc(100vw - 4vw)`,
        '@media (min-width: 768px)':{
          width: 'auto',
          marginX: '5%'
        },
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '32px',
            width: '100%',
            '@media (max-width: 768px)': {
              flexDirection: 'column',
              gap:'16px',
              marginBottom: '24px',
            }
          }}
        >
          <Box sx={{ display: 'flex', position: 'relative'}}>
            <GroupIcon
              style={{
                color: '#083CA6',
                background: '#F2F5FD',
                width: '48px',
                height: '48px',
                padding: '12px',
                borderRadius: '50%',
                marginRight: '16px',
              }}
            />
            <Typography
              style={{
                fontSize: '2rem',
                color: '#083CA6',
              }}
            >
              {props.profile ? 'Perfis de usuário' : 'Usuários'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', '@media (max-width: 768px)': {width: '100%', flexDirection: 'column'} }}>
            {props.profile && (
              <FormControl
                sx={{ width: 'auto', marginRight: '16px', marginLeft: '16px', '@media (max-width: 768px)': { marginLeft: '0px', width: '100%' } }}
                size='small'
              >
                <InputLabel id='filter-label-profile'>
                  Filtrar Sistema
                </InputLabel>
                <Select
                  labelId='filter-label-profile'
                  value={pageFilterProfile}
                  label='Filtrar Sistema'
                  onChange={(event) => setPageFilterProfile(event.target.value)}
                >
                  <MenuItem key={0} value='Todos os sistemas'>
                    Todos os sistemas
                  </MenuItem>
                  {filterList.map((filter, index) => (
                    <MenuItem key={index + 1} value={filter}>
                      {filter}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {!props.profile && (
              <FormControl
                sx={{ '@media (min-width: 768px)':{ width: '350px' }, width: '100%' }}
                size='small'
              >
                <TextField
                  value={pageFilterUser}
                  onChange={(event) => setPageFilterUser(event.target.value)}
                  label='Pesquise usuários por nome ou CPF'
                  inputProps={{ style: { height: '7px' } }}
                  sx={{ '& label[data-shrink="false"]': { top: '-7px' } }}
                />
              </FormControl>
            )}
            <Button
              variant='contained'
              disableElevation
              startIcon={<PersonAddIcon />}
              style={{
                backgroundColor: '#083CA6',
                textTransform: 'none',
                fontWeight: '700',
              }}
              className=''
              onClick={() => handleButtonAction()}
            >
              {props.profile
                ? 'Adicionar perfil de usuário'
                : 'Adicionar usuário'}
            </Button>
          </Box>
        </Box>
        <TableContainer className='mb-[24px]'>
          <Table
            sx={{
              tableLayout: 'fixed',
              border: '2px solid #F7F8FA',
              width: '1200px',
              '@media (min-width: 768px)': {
                width: '100%',
              }
            }}
            padding='none'
          >
            <TableHead>
              <TableRow style={{ height: '48px', background: '#F7F8FA' }}>
                <TableCell
                  sx={{ padding: '0 8px', fontWeight: 600, '@media (min-width: 768px)':{padding: '0 16px'}}}
                  width={props.profile ? '20%' : '10%'}
                  align='left'
                  className=''
                >
                  Nome
                </TableCell>
                <TableCell
                  sx={{ padding: '0 8px', fontWeight: 600, '@media (min-width: 768px)':{padding: '0 16px'}}}
                  width={props.profile ? '20%' : '10%'}
                  align='left'
                >
                  Sistemas de acesso
                </TableCell>
                {!props.profile && (
                  <TableCell
                    sx={{ padding: '0 8px', fontWeight: 600, '@media (min-width: 768px)':{padding: '0 16px'}}}
                    width='16%'
                    align='left'
                  >
                    Perfil
                  </TableCell>
                )}
                <TableCell
                  sx={{ padding: '0 8px', fontWeight: 600, '@media (min-width: 768px)':{padding: '0 16px'}}}
                  width={props.profile ? '20%' : '10%'}
                  align='left'
                >
                  Data de inclusão
                </TableCell>
                {!props.profile && (
                  <TableCell
                    sx={{ padding: '0 8px', fontWeight: 600, '@media (min-width: 768px)':{padding: '0 16px'}}}
                    width='16%'
                    align='left'
                  >
                    Status do usuário
                  </TableCell>
                )}
                <TableCell
                  sx={{ padding: '0 8px', fontWeight: 600, '@media (min-width: 768px)':{padding: '0 16px'}}}
                  width={props.profile ? '25%' : '20%'}
                  align='center'
                >
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map((row, index) => (
                <TableRow key={`row-${index}`}>
                  <TableCell
                    sx={{ padding: '0 8px', '@media (min-width: 768px)':{padding: '0 16px'}}}
                    width={props.profile ? '20%' : '10%'}
                    className='truncate ...'
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    sx={{ padding: '0 8px', '@media (min-width: 768px)':{padding: '0 16px'}}}
                    width={props.profile ? '20%' : '10%'}
                    className='truncate ...'
                  >
                    {row.accessSystems.join(', ')}
                  </TableCell>
                  {!props.profile && (
                    <TableCell
                      sx={{ padding: '0 8px', '@media (min-width: 768px)':{padding: '0 16px'}}}
                      width='16%'
                      className='truncate ...'
                    >
                      {row.profiles.map((profile : any) => {
                        return profile.descricao_perfil + ' ';
                      })}
                    </TableCell>
                  )}
                  <TableCell
                    sx={{ padding: '0 8px', '@media (min-width: 768px)':{padding: '0 16px'}}}
                    width={props.profile ? '20%' : '10%'}
                    className='truncate ...'
                  >
                    {row.inclusionDate}
                  </TableCell>
                  {!props.profile && (
                    <TableCell sx={{ padding: '0 8px', '@media (min-width: 768px)':{padding: '0 16px'}}} width='16%'>
                      <FormControlLabel
                        control={
                          <CustomSwitch
                            sx={{ m: 1 }}
                            checked={row.status}
                            onChange={(e) => handleSwitch(e, row)}
                          />
                        }
                        label={
                          <Typography>
                            {row.status ? 'Ativo' : 'Inativo'}
                          </Typography>
                        }
                      />
                    </TableCell>
                  )}
                  <TableCell
                    sx={{ padding: '0 8px', '@media (min-width: 768px)':{padding: '0 16px'}}}
                    width={props.profile ? '25%' : '20%'}
                    align='right'
                  >
                    <FormControlLabel
                      control={
                        <IconButton
                          size='small'
                          aria-label='Editar'
                          onClick={() => handleEdit(row)}
                          disableRipple
                        >
                          <EditIcon sx={{ m: 1 }} />
                        </IconButton>
                      }
                      label={
                        <Typography sx={{ fontWeight: 600 }} color='disabled'>
                          Editar
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <IconButton
                          size='small'
                          aria-label='Excluir'
                          onClick={() => handleDelete(row)}
                          disableRipple
                        >
                          <DeleteIcon color='error' sx={{ m: 1 }} />
                        </IconButton>
                      }
                      label={
                        <Typography sx={{ fontWeight: 600 }} color='error'>
                          Excluir
                        </Typography>
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box display='flex' justifyContent='right'>
        <CustomPagination
          variant='outlined'
          shape='rounded'
          count={pageCount}
          page={page}
          onChange={(e, value) => props.profile ? setPage(value) : changePage(value)}
        />
      </Box>
    </Box>
  )
}
