import { ISchemaFormData, IStudents } from "../types";

export const serializeStudent = {
  toFormDate: (dataStudent: IStudents) => {
    if (!Object.keys(dataStudent).length) return {} as ISchemaFormData;
    const {
      codRegistration,
      nameMother,
      nameStudent,
      birthDate,
      rg,
      genre,
      createdAt,
      teachingMode,
    } = dataStudent;
    const [year, nameClass] = dataStudent.nameClass.split(" ");
    return {
      codRegistration,
      nameMother,
      nameStudent,
      birthDate,
      rg,
      genre,
      year,
      nameClass,
      teachingMode,
      createdAt,
    } as ISchemaFormData;
  },
  toDataStudent: (formData: ISchemaFormData) => {
    if (!Object.keys(formData).length) return {} as IStudents;
    const {
      codRegistration,
      nameMother,
      nameStudent,
      birthDate,
      rg,
      genre,
      createdAt,
      teachingMode,
    } = formData;
    const nameClass = `${formData.year} ${formData.nameClass}`;
    return {
      codRegistration,
      nameStudent,
      nameClass,
      nameMother,
      birthDate,
      genre,
      rg,
      teachingMode,
      createdAt,
    } as IStudents;
  },
};
