import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clearLocalStorage } from "../services/localStorage";


export function Logout() {
	const navigate = useNavigate();

  useEffect(() => {
		clearLocalStorage();
		navigate('/login');
	}, [])

	return (
		<></>
	)
}
