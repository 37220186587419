import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Home } from '../views/Home'
import { Login } from '../views/Login'
import { Register } from '../views/Register'
import { Profile } from '../views/Profile'
import { Management } from '../views/Management'

import { ProtectedRoute } from './ProtectedRoute'
import { Logout } from '../views/Logout'
import ManageSchool from '../views/ManageSchools'
import ManageClasses from '../views/ManageClasses'
import ManageStudents from '../views/ManageStudents'

export default function MainRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/resgister' element={<Register />} />
        <Route path='/logout' element={<Logout />} />

        <Route
          path='/'
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />

        <Route
          path='/profile'
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path='/management'
          element={
            <ProtectedRoute>
              <Management />
            </ProtectedRoute>
          }
        />

        <Route
          path='/escolas'
          element={
            <ProtectedRoute>
              <ManageSchool />
            </ProtectedRoute>
          }
        />

        <Route
          path='/turmas'
          element={
            <ProtectedRoute>
              <ManageClasses />
            </ProtectedRoute>
          }
        />

        <Route
          path='/alunos'
          element={
            <ProtectedRoute>
              <ManageStudents />
            </ProtectedRoute>
          }
        />

        <Route path='*' element={<p>Página não encontrada: 404!</p>} />
      </Routes>
    </BrowserRouter>
  )
}
