import axios from 'axios';

import { getLocalToken } from "../services/localStorage";

const api = axios.create({
  baseURL: 'https://scanner-prova.rj.r.appspot.com/'
});

export const getProfilesData = async () => {
  const token = getLocalToken();
  const res = await api.get('govbr/perfil/', {
    headers:{
      'Authorization': `Bearer ${token}`
    }
  });

  return res.data;
}

export const editProfile = async (info : any) => {
  const token = getLocalToken();
  const res = await api.put(`govbr/perfil/${info.id}`, {
    nivel: info.nivel,
    descricao_perfil: info.descricao_perfil,
    acesso_escolas: true
  }, {
    headers:{
      'Authorization': `Bearer ${token}`
    }
  });

  return (res.data);
}

export const addProfile = async (info : any) => {
  const token = getLocalToken();
  const res = await api.post('govbr/perfil/', {
    nivel: info.nivel,
    descricao_perfil: info.name,
    acesso_escolas: true
  }, {
    headers:{
      'Authorization': `Bearer ${token}`
    }
  });

  return res;
}

export const deleteProfile = async (profileId: string) => {
  const token = getLocalToken();
  const res = await api.delete(`govbr/perfil/${profileId}`, {
    headers:{
      'Authorization': `Bearer ${token}`
    }
  });

  return (res.status === 204);
}

export const linkUserToProfile = async (info : any) => {
  const token = getLocalToken();
  const cleanCPF = info.userId.replace(/[^\d]/g, '');
  const res = await api.post(`govbr/perfil/usuario/`, {
    id_perfil: +info.profileId,
    id_usuario: cleanCPF,
    id_escola: +info.schoolId,
    id_concessor: info.id
  }, {
    headers:{
      'Authorization': `Bearer ${token}`
    }
  });

  return (res.status === 200 || res.status === 201);
}

export const deleteUserProfile = async (id: any) => {
  const token = getLocalToken();
  const res = await api.delete(`govbr/perfil/usuario/${id}`, {
    headers:{
      'Authorization': `Bearer ${token}`
    }
  });

  return (res.status === 204);
}

export const getUsersProfiles = async () => {
  const token = getLocalToken();
  const res = await api.get(`govbr/perfil/usuario/`, {
    headers:{
      'Authorization': `Bearer ${token}`
    }
  });

  return res.data;
}