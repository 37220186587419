import { Fragment } from 'react';
import { createRoot } from 'react-dom/client';

import { Dialog, Transition } from '@headlessui/react';

import { Alert } from '../../../components/Alert';

import { deleteUser } from '../../../api/user';

import warningIcon from '../../../assets/icons/warning.svg';
import closeIcon from '../../../assets/icons/blackCloseIcon.svg';

export default function DeleteUser(userId: string, user: string) {
  return new Promise((resolve, reject) => {
    addDialog(userId, user, resolve);
  });
}

function addDialog(userId: string, user: string, resolve: any) {
  const body = document.getElementsByTagName('body')[0];
  const div = document.createElement('div');

  div.setAttribute('id', 'modal-delete-user');
  body.appendChild(div);

  const root = createRoot(div);

  root.render(
    <CreateDialog 
      root={root} 
      userId={userId} 
      user={user} 
      resolve={resolve}
    />
  );
}

function removeDialog(root: any) {
  root.unmount();

  const div = document.getElementById('modal-delete-user');
  if (div) {
    div.remove();
  }
}

function CreateDialog(dialogObj: { root: any, userId: string, user: string, resolve: any }) {
  const handleClose = () => {
    removeDialog(dialogObj.root);

    dialogObj.resolve();
  };

  const handleDelete = () => {
    removeDialog(dialogObj.root);

    deleteUser(dialogObj.userId)
      .then((res: boolean) => {
        dialogObj.resolve({data: res, changed: true});
      })
      .catch((err: any) => {
        Alert({
          type: 'error',
          message: 'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
          autoClose: true,
        })

        dialogObj.resolve(false);
      });
  };

  return (
    <Transition.Root show as={Fragment}>
      <Dialog as="div" className="w-full relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#14161F] bg-opacity-40 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-2xl sm:w-full">
                <div className="bg-white">
                  <div className="sm:flex sm:items-start">
                    <div className="text-center sm:text-left w-full">
                      <div className="flex flex-row justify-between mx-8 mt-8">
                        <Dialog.Title as="h3" className="text-lg font-medium">
                          {`Tem certeza que deseja excluir o usuário ${dialogObj.user}?`}
                        </Dialog.Title>
                        <img className="cursor-pointer" src={closeIcon} alt="Imagem de um x" onClick={handleClose} />
                      </div>
                      <div className="mt-10 p-2 mx-8 flex flex-row bg-[#FEF2F2]">
                        <img src={warningIcon} alt="Símbolo de aviso" />
                        <p className="ml-4 text-sm text-[#920000] rounded">
                          <span className="font-bold mr-1">
                            Atenção!
                          </span>
                          Esta ação não pode ser desfeita. Ao excluir este usuário, ele não terá mais acesso à plataforma.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white mt-11 flex justify-between gap-4">
                  <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded px-4 py-2 bg-white text-sm font-medium mb-7 ml-8"
                    onClick={handleClose}
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded px-4 py-2 bg-[#920000] text-sm font-bold text-white mb-7 mr-8"
                    onClick={handleDelete}
                  >
                    Excluir perfil
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}