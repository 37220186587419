import { Grid, MenuItem, TextField, Button, Box } from "@mui/material";
import { useState } from "react";
import { IForm } from "../../types";
import * as yup from "yup";
import { Formik, Field } from "formik";
const MaintenanceClassForm: React.FC<IForm> = ({
  data,
  onConfirm,
  onCancel,
  confirmButtonLabel,
}) => {
  const optionsSelect = [...Array(9).keys()].map((y) => `${y + 1}º`);
  const [submit, setSubmit] = useState(false);
  const yupValidation = yup.object({
    year: yup.string().required("É necessário selecionar o ano da turma."),
    name: yup.string().required("É necessário preencher o nome da turma."),
    cpf: yup.string().required("É necessário informar o cpf do professor"),
    teacher: yup.string().required("É necessário informar o nome do professor"),
  });

  return (
    <Formik
      initialValues={data}
      validationSchema={yupValidation}
      onSubmit={(values) => {
        onConfirm(values);
      }}
    >
      {({ handleSubmit, errors, values }) => (
        <form
          style={{ width: "100%" }}
          onSubmit={(event) => {
            handleSubmit(event);
            setSubmit(true);
          }}
        >
          <Grid container columnSpacing={3} rowSpacing={4}>
            <Grid item xs={6}>
              <Field
                as={TextField}
                select
                id="year"
                name="year"
                label="Ano"
                helperText={submit && errors.year}
                error={submit && !!errors.year}
                variant="outlined"
                placeholder="Selecione o ano"
                fullWidth
              >
                {optionsSelect.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                fullWidth
                id="name"
                name="name"
                label="Nome"
                variant="outlined"
                placeholder="Digite aqui o nome da turma"
                helperText={submit && errors.name}
                error={submit && errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                fullWidth
                id="cpf"
                name="cpf"
                label="CPF do professor responsável"
                variant="outlined"
                placeholder="Digite aqui o CPF"
                helperText={submit && errors.cpf}
                error={submit && errors.cpf}
              />
            </Grid>
            {values.cpf && !errors.cpf && (
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  fullWidth
                  id="teacher"
                  name="teacher"
                  label="Nome do professor"
                  variant="outlined"
                  placeholder="Digite aqui o nome"
                  helperText={submit && errors.teacher}
                  error={submit && errors.teacher}
                />
              </Grid>
            )}
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "40px",
            }}
          >
            <Button
              variant="text"
              style={{
                color: "black",
                textTransform: "none",
                fontWeight: "700",
              }}
              disableElevation
              type="reset"
              onClick={onCancel}
            >
              Cancelar
            </Button>

            <Button
              disableElevation
              style={{
                backgroundColor: "#083CA6",
                textTransform: "none",
                fontWeight: "700",
                color: "white",
              }}
              type="submit"
            >
              {confirmButtonLabel}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default MaintenanceClassForm;
