import { Fragment, useState } from 'react';
import { createRoot } from 'react-dom/client';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { Dialog, Transition } from '@headlessui/react';

import { Alert } from '../../../components/Alert';

import { editProfile } from '../../../api/profile';

import closeIcon from '../../../assets/icons/blackCloseIcon.svg';

export function EditProfile(profileId: string, profile: string) {
  return new Promise((resolve, reject) => {
    addDialog(profileId, profile, resolve);
  });
}

function removeDialog(root: any) {
  root.unmount();

  const div = document.getElementById('modal-edit-profile');
  if (div) {
    div.remove();
  }
}

function addDialog(profileId: string, profile: string, resolve: any) {
  const body = document.getElementsByTagName('body')[0];
  const div = document.createElement('div');

  div.setAttribute('id', 'modal-edit-profile');
  body.appendChild(div);

  const root = createRoot(div);

  root.render(
    <CreateDialog 
      root={root} 
      profileId={profileId} 
      profile={profile} 
      resolve={resolve}
    />
  );
}

function CreateDialog(dialogObj :{root: any, profileId: string, profile: string, resolve : any}) {
  const [newProfile, setNewProfile] = useState(dialogObj.profile);
  const [tab, setTab] = useState(0);
  const [tabs] = useState([
    {
      id: 0,
      label: 'Informações',
      tabMargin: 0,
      tabLength: 100,
    },
    {
      id: 1,
      label: 'Permissões',
      tabMargin: 32,
      tabLength: 100,
    }
  ]);

  const handleClose = () => {
    removeDialog(dialogObj.root);

    dialogObj.resolve({ changed: false });
  };

  const handleEdit = () => {
    removeDialog(dialogObj.root);

    editProfile({id: dialogObj.profileId, descricao_perfil: newProfile, nivel: 50})
      .then((res: any) => {
        dialogObj.resolve([{
          changed: true,
          data: res,
        }]);
      })
      .catch((err: any) => {
        Alert({
          type: 'error',
          message: 'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
          autoClose: true,
        })

        dialogObj.resolve({ changed: false });
      });
  };

  const handleTabChange = (newValue: number) => {
    setTab(newValue);
  }

  const getWidth = (tab: number) => {
    return tabs.find((item: any) => item.id === tab)?.tabLength;
  }

  const calculateMargin = (tab: number) => {
    if (tab === 0) {
      return 0;
    } else {
      const index = tabs.findIndex((item: any) => item.id === tab);

      return tabs.slice(0, index).reduce((accumulator: number, currentValue: any) => {
        return accumulator + currentValue.tabLength + currentValue.tabMargin + 32;
      }, 0);
    }
  };

  return (
    <>      
      <Transition.Root show as="div">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#14161F]  bg-opacity-40 transition-opacity" />
        </Transition.Child>

        <Dialog as="div" className="w-full z-10 relative" onClose={handleClose} id="basic">
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                leave="ease-in duration-200"
              >
                <Dialog.Panel className="relative w-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-screen-sm sm:w-full">
                  <div className="bg-white">
                    <div className="sm:flex sm:items-start">
                      <div className="text-center sm:text-left w-full">
                        <div className="flex flex-row justify-between mx-8 mt-8">
                          <Dialog.Title as="h3" className="text-lg font-medium">
                            Editar usuário
                          </Dialog.Title>
                          <img id="close_profile" className="cursor-pointer" src={closeIcon} alt="Imagem de um x" onClick={handleClose} />
                        </div>
                        <div className="flex ml-8 mt-12">
                          {tabs.map((currentTab, index) => (
                            <Button 
                              variant='text'
                              style={{
                                color: tab === currentTab.id ? '#083CA6' : '#404554',
                                fontSize: '1rem',
                                fontWeight: 600,
                                textTransform: 'none',
                                padding: '0px',
                                marginLeft: `${currentTab.tabMargin}px`,
                                width: `${currentTab.tabLength}px`,
                              }}
                              onClick={() => handleTabChange(currentTab.id)}
                              key={index}
                            >
                              {currentTab.label}
                            </Button>
                          ))}
                        </div>
                        <Box sx={{
                          height: '3px',
                          maxWidth: '90%',
                          borderRadius: '6px',
                          background: '#B6C4EE',
                          marginLeft: '32px'
                        }}>
                          <Box sx={{
                            height: '3px',
                            width: `${getWidth(tab)}px`,
                            borderRadius: '6px',
                            marginLeft: `${calculateMargin(tab)}px`,
                            transition: 'all 0.3s ease-in-out',
                            background: '#083CA6',
                          }}></Box>
                        </Box>
                      </div>
                    </div>
                  </div>
                  {tab === 0 && (
                    <>
                      <div className="flex flex-col justify-start bg-white rounded-md">
                        <div className="flex flex-col mt-12 ml-8 mr-10">
                          <label className="text-[#5E6475] text-sm">Nome</label>
                          <input className="w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded placeholder:text-[#9EA1B0]" type="text" placeholder="Digite aqui o nome do perfil de usuário" value={newProfile} onChange={(e) => setNewProfile(e.target.value)} />
                        </div>
                        <div className="flex flex-col mt-2 ml-8 mr-10 opacity-50">
                          <label className="text-[#BEC0CC] text-sm">Sistema</label>
                          <select className="w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] text-[#BEC0CC] rounded" disabled>
                            <option value="1" className="text-black">SAP</option>
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {tab === 1 && (
                    <>
                      <div className="flex flex-col justify-start bg-white rounded-md">
                        <div className="ml-8 mt-10">
                          <p className="text-sm">
                            Selecione as permissões que o perfil de usuário <span className="font-bold">Super Usuário</span> terá no sistema <span className="font-bold">Comunidade de Práticas:</span>
                          </p>
                          <div className="mt-5" >
                            <input type="checkbox" name="permission" id="1" />
                            <label className="ml-3 text-profile">Permissão 1</label>
                          </div>
                          <div className="mt-4" >
                            <input type="checkbox" name="permission2" id="2" />
                            <label className="ml-3 text-profile">Permissão 2</label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="bg-white mt-11 flex justify-between gap-4">
                    <button
                      id="cancel_profile"
                      type="button"
                      className="w-auto inline-flex justify-center rounded px-4 py-2 bg-white text-sm font-bold mb-7 ml-8"
                    >
                      Descartar alterações
                    </button>
                    <button
                      id="edit_profile"
                      type="button"
                      className="w-auto inline-flex justify-center rounded px-4 py-2 bg-[#083CA6] text-sm font-bold text-white mb-7 mr-8"
                      onClick={() => handleEdit()}
                    >
                      Salvar alterações
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}