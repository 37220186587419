import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'

import jwt_decode from 'jwt-decode'

import { 
  getLocalToken, 
  removeLocalToken, 
  setLocalRefreshToken, 
  setLocalToken 
} from '../services/localStorage'
import AuthGovService from '../services/auth-gov'

import loginLeft from '../assets/images/loginBanner.jpg'

export interface JWT {
  exp: number
}

export function Login() {
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState('')
  const [url, setUrl] = useState('')
  const navigate = useNavigate()

  async function getTokens(code: string | unknown, redirect_uri: string) {
    const api = await AuthGovService().getAuthTokens({
      code,
      redirect_uri,
    })

    if (api.status === 200) {
      setLocalToken(api.data.access)
      setLocalRefreshToken(api.data.access)
      return navigate('/')
    }
    return api
  }

  useEffect(() => {
    document.title = 'Login'
    setToken(getLocalToken())
    if (token) {
      try {
        const jwt: JWT = jwt_decode(token)

        if (Date.now() >= jwt.exp * 1000) {
          removeLocalToken()
        }
        navigate('/')
      } catch (err) {
        console.log(err)
      }
    }
  }, [navigate, token])

  useEffect(() => {
    const params = queryString.parse(window.location.search)
    if (params.code) {
      getTokens(params.code, 'https://sap-ae2.pages.dev/login')
    }

    AuthGovService()
      .getUrl({ redirect_uri: 'https://sap-ae2.pages.dev/login' })
      .then((res) => {
        setUrl(res.data)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className='grid grid-cols-1 md:grid-cols-2 w-[100%]'
      style={{ height: '100%' }}
    >
      <div className='mx-[auto] my-[auto] w-[100%] px-[20px]'>
        <p
          className='mb-[35px] text-[#002577] text-[20px]'
          style={{ fontWeight: '700' }}
        >
          Plataforma de Recuperação de Aprendizagem e Redução de Abandono
        </p>
        <a className='text-profile bg-[#083CA6] text-white font-bold py-2 px-6 rounded-full' type='button' href={url}>
          Entrar com gov.br
        </a>
      </div>
      <div
        className='hidden md:block'
        style={{
          backgroundImage: `url(${loginLeft})`,
          height: '100%',
          backgroundRepeat: 'no-repeat, repeat',
          backgroundSize: 'cover',
        }}
      ></div>
    </div>
  )
}
