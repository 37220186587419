import * as React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

type Props = {
  steps: any
  finishLabel: string
  nextLabel: string
  cancelLabel: string
  children: any
  close: (value: boolean) => void
  onSubmit?: any
}

export default function StepperComponent(props: Props) {
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set<number>())

  const [steps, setSteps] = React.useState([])

  React.useEffect(() => {
    setSteps(props.steps)
  }, [props.steps])

  // const isStepOptional = (step: number) => {
  //   return step === 1
  // }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleClose = () => {
    props.close(false)
  }

  const onSubmit = () => {
    props.onSubmit()
  }

  return (
    <Box sx={{ width: '100%', px: 2, display: 'table', mx: 'auto' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}

          if (isStepSkipped(index)) {
            stepProps.completed = false
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>

      <React.Fragment>
        <Typography sx={{ display: 'table', mt: 2, mb: 1, mx: 'auto' }}>
          {props.children[activeStep]}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            color='inherit'
            onClick={handleBack}
            sx={{
              mr: 1,
              display: activeStep === 0 ? 'none' : 'flex',

              flexDirection: 'row',
              alignItems: 'center',
              padding: '8px 16px',
              gap: '8px',

              width: '74px',
              height: '40px',

              borderRadius: '4px',

              /* Inside auto layout */

              flex: 'none',
              order: 0,
              flexGrow: 0,
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '100%',
              textTransform: 'capitalize',
            }}
          >
            Voltar
          </Button>

          <Button
            color='inherit'
            onClick={handleClose}
            sx={{
              mr: 1,
              display: activeStep === 0 ? 'flex' : 'none',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '8px 16px',
              gap: '8px',

              width: '74px',
              height: '40px',

              borderRadius: '4px',

              /* Inside auto layout */

              flex: 'none',
              order: 0,
              flexGrow: 0,
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '100%',
              textTransform: 'capitalize',
            }}
          >
            {props.cancelLabel}
          </Button>

          <Box sx={{ flex: '1 1 auto' }} />

          <Button
            variant='contained'
            onClick={activeStep === steps.length - 1 ? onSubmit : handleNext}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '8px 16px',
              gap: '8px',

              height: '40px',

              background: '#083CA6',
              borderRadius: '4px',

              flex: 'none',
              order: 0,
              flexGrow: 0,
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '100%',
              textTransform: 'capitalize',
              color: 'white',
            }}
          >
            {activeStep === steps.length - 1
              ? props.finishLabel
              : props.nextLabel}
          </Button>
        </Box>
      </React.Fragment>
    </Box>
  )
}
