import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Dropdown } from '../components/Dropdown'

import AuthGovService from '../services/auth-gov'
import { getLocalToken } from '../services/localStorage'

import { MainHeader } from '../components/Header'

import file from '../assets/icons/file.svg'
import arrowRight from '../assets/icons/arrowRight.svg'

export function Home() {
  const token = getLocalToken()
  const [name, setName] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Home'

    AuthGovService()
      .getUserData()
      .then((res) => {
        setName(res.data.nome)
      })
  }, [])

  const toManagement = () => navigate('/management', { state: { name } })

  return (
    <div className='w-screen h-full md:w-auto flex flex-col'>
      <MainHeader username={name} subtitles={['Página inicial']} />
      <div className='mobile:flex tablet:hidden py-2 justify-content-end mx-8'>
        <Dropdown username={name}/>
      </div>
      <div className="bg-center w-auto bg-[url('/src/assets/images/banner.png')] bg-no-repeat bg-cover resize flex items-center">
        <div className='flex flex-col py-12 ml-8 lg:py-[86px] lg:ml-[6.75rem]'>
          <h1 className='text-card leading-none text-white font-medium md:text-[52px]'>
            Bem-vindo(a) de volta. 
          </h1>
          <p className='text-sm text-white leading-none mt-4 lg:mt-5 lg:text-xl1 lg:leading-[140%]'>
            Políticas para recuperação de aprendizagem e <br/> 
            redução do abandono em um só lugar.
          </p>
        </div>
      </div>
      <div
        style={{
          maxWidth: '1224px',
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <div>
          {/* GESTÃO */}
          <section className='ml-10 mr-10 mt-12 mb-2'>
            <h1 className='text-2xl font-medium lg:text-card flex-1'>Gestão</h1>
            <br />
            <div className='max-w-sm rounded overflow-hidden shadow-lg'>
              <div className='w-full bg-[#083CA6] p-3 md:p-6'>
                <div className='flex justify-center rounded-full bg-white w-[58px] h-[58px]'>
                  <img
                    src={file}
                    alt='Arquivo'
                    className='pl-3 pt-3 pr-3 pb-3'
                  />
                </div>
              </div>
              <div className='px-6 py-4'>
                <div className='text-2xl font-medium lg:text-card mb-2'>
                  Usuários
                </div>
                <p className='text-gray-700 text-sm lg:text-profile'>
                  Gerencie as contas de usuário cadastradas, altere permissões,
                  entre outros.
                </p>
              </div>
              <div className='px-6 pt-4 pb-2 flex justify-center'>
                <button
                  type='button'
                  onClick={toManagement}
                  className='flex justify-center items-center content-center border-solid border-2 border-gray-200 rounded-lg w-full'
                >
                  <div>
                    <img
                      src={arrowRight}
                      alt='Arquivo'
                      className='mr-3 ml-3 mb-2 mt-2'
                      style={{ display: 'inline', width: '20px' }}
                    />
                  </div>
                  <div className='mr-3 ml-3 mb-2 mt-2'>Ver mais</div>
                </button>
              </div>
            </div>
          </section>

          {/* PROGRAMAS */}
          <section className='ml-10 mr-10 mt-12 mb-2'>
            <h1 className='text-2xl font-medium lg:text-card mb-2'>Programas</h1>
            <br />
            <div className='max-w-sm rounded overflow-hidden shadow-lg'>
              <div className='w-full bg-[#083CA6] p-3 md:p-6'>
                <div className='flex justify-center rounded-full bg-white w-[58px] h-[58px]'>
                  <img
                    src={file}
                    alt='Arquivo'
                    className='pl-3 pt-3 pr-3 pb-3'
                  />
                </div>
              </div>
              <div className='px-6 py-4'>
                <div className='text-2xl font-medium lg:text-card mb-2'>SAP</div>
                <p className='text-gray-700 text-sm lg:text-profile'>
                  Sistema de Alerta Preventivo
                </p>
              </div>
              <div className='px-6 pt-4 pb-2 flex justify-center'>
                <button
                  type='button'
                  onClick={(event) =>
                    (window.location.href = `https://acesso-sap-dot-scanner-prova.rj.r.appspot.com/?accessToken=${token}&refreshToken=${token}`)
                  }
                  className='flex justify-center items-center content-center border-solid border-2 border-gray-200 rounded-lg w-full'
                >
                  <div>
                    <img
                      src={arrowRight}
                      alt='Arquivo'
                      className='mr-3 ml-3 mb-2 mt-2'
                      style={{ display: 'inline', width: '20px' }}
                    />
                  </div>
                  <div className='mr-3 ml-3 mb-2 mt-2'>Ver mais</div>
                </button>
              </div>
            </div>
          </section>

          {/* OBSESRVATORIO DA EQUIDADE */}
          <section className='ml-10 mr-10 mt-12 mb-2'></section>
        </div>
      </div>
      <br />
    </div>
  )
}
