import { useEffect, useState } from 'react';

import { Alert } from '../Alert';

import { getLocalCPF } from '../../services/localStorage';

import { editPassword } from '../../api/user';

import blackCloseImg from '../../assets/icons/blackCloseIcon.svg';
import closedEyeImg from '../../assets/icons/closedEye.svg';
import success from '../../assets/icons/success.svg';
import warning from '../../assets/icons/warning.svg';

interface ContactInfoProps {
  setHidden: () => void;
  hidden: boolean;
}

export function EditPassword({ setHidden, hidden }: ContactInfoProps) {
  const cpf = getLocalCPF();
  const [inputCurrentPassword, setInputCurrentPassword] = useState('');
  const [inputNewPassword, setInputNewPassword] = useState('');
  const [inputConfirmPassword, setInputConfirmPassword] = useState('');

  const [isHidden , setIsHidden] = useState(false)
  const [isSuccessHidden, setIsSuccessHidden] = useState(false)
  const [isErrorHidden, setIsErrorHidden] = useState(false)

  const [toggleVisibilityCurrent, setToggleVisibilityCurrent] = useState(true)
  const [toggleVisibilityNew, setToggleVisibilityNew] = useState(true)
  const [toggleVisibilityConfirmNew, setToggleVisibilityConfirmNew] = useState(true)
  
  useEffect(() => {
    setIsHidden(hidden);
  }, [hidden]);

  const checkNull = () => {
    if(
      inputCurrentPassword === '' ||
      inputNewPassword === '' ||
      inputConfirmPassword === ''
    ) {
      return true;
    }
    return false;
  };

  const setToNull = () => {
    setInputCurrentPassword('');
    setInputNewPassword('');
    setInputConfirmPassword('');
  };

  const handleCloseContactInfo = () => {
    setToNull();
    setIsHidden(!isHidden);
    setHidden();
  };

  const handleSaveChanges = async () => {
    if(checkNull()) {
      setIsErrorHidden(true);
      handleCloseContactInfo();
      return;
    }

    const editSuccess = await editPassword(cpf, {
      old: inputCurrentPassword,
      new: inputConfirmPassword,
    });

    if (editSuccess) {
      handleCloseContactInfo();
    } else {
      Alert({
        type: 'error',
        message: 'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
        autoClose: true,
      });
      setIsHidden(!isHidden);
      setHidden();
    }
  };
  
  return (
    <>
      {
        isSuccessHidden ?
          <div className='w-full mt-16 flex justify-center absolute z-50'>
            <div className='w-2/5 h-fit flex flex-row items-center py-4 bg-[#F4FDF4] rounded border-[#B2EDB0] border-x border-y '>
              <img src={success} alt='Imagem de um Ok' className='ml-4' />
              <span className='w-4/5 ml-3 text-[#196416] text-profile font-medium'>Senha alterada com sucesso.</span>
              <img src={blackCloseImg} alt='Imagem de um x' className='h-3 w-3 ml-3 mr-6 cursor-pointer' onClick={() => setIsSuccessHidden(!isSuccessHidden)} />
            </div>
          </div>
        : null
      }
      {
        isErrorHidden ?
          <div className='w-full mt-16 flex justify-center absolute z-50'>
            <div className='w-2/5 h-fit flex flex-row items-center py-4 bg-[#F3D8DB] rounded border-[#EDC4C8] border-x border-y'>
              <img src={warning} alt='Imagem de um aviso' className='ml-4' />
              <span className='w-4/5 ml-3 text-[#920000] text-profile font-medium'>Ops! Ocorreu um erro ao salvar as alterações. Tente novamente mais tarde.</span>
              <img src={blackCloseImg} alt='Imagem de um x' className='h-3 w-3 ml-3 mr-6 cursor-pointer' onClick={() => setIsErrorHidden(!isErrorHidden)} />
            </div>
          </div>
        : null
      }
      {
        isHidden ? 
          <section className='w-full h-full z-50 fixed flex items-center justify-center bg-[#14161f66]'>
            <div className='md:w-2/5 w-full flex flex-col justify-start bg-white rounded-md'>
              <div className='w-full flex flex-row justify-start relative'>
                <h2 className='w-4/5 text-xl font-medium mx-8 mt-8'>Alterar senha</h2>
                <img src={blackCloseImg} alt='Imagem de um x' className='mt-10 mr-10 w-4 h-4 cursor-pointer' onClick={handleCloseContactInfo} />
              </div>
              <div className='flex flex-col mt-12 ml-8 mr-10'>
                <label className='text-[#5E6475] text-sm'>Senha atual</label>
                <div className='flex flex-row px-4 py-2 mt-2 bg-[#F7F8FA] border-2 focus-within:bg-white focus-within:border-2 focus-within:border-black rounded'>
                  <input 
                    type={toggleVisibilityCurrent ? 'password' : 'text'}
                    value={inputCurrentPassword}
                    onChange={(e) => setInputCurrentPassword(e.target.value)}
                    className='w-full text-sm bg-[#F7F8FA] focus:bg-white outline-none' 
                  />
                  <span onClick={event => setToggleVisibilityCurrent(!toggleVisibilityCurrent)} className='w-6 h-4'>
                    <img src={closedEyeImg} alt='Imagem de um olho fechado' />  
                  </span>
                </div>
              </div>
              <div className='flex flex-col mt-2 ml-8 mr-10'>
                <label className='text-[#5E6475] text-sm'>Nova senha</label>
                <div className='flex flex-row px-4 py-2 mt-2 bg-[#F7F8FA] border-2 focus-within:bg-white focus-within:border-2 focus-within:border-black rounded'>
                  <input 
                    type={toggleVisibilityNew ? 'password' : 'text'}
                    value={inputNewPassword}
                    onChange={(e) => setInputNewPassword(e.target.value)}
                    className='w-full text-sm bg-[#F7F8FA] focus:bg-white outline-none' 
                  />
                  <span onClick={event => setToggleVisibilityNew(!toggleVisibilityNew)} className='w-6 h-4'>
                    <img src={closedEyeImg} alt='Imagem de um olho fechado' />  
                  </span>
                </div>
              </div>
              <div className='flex flex-col mt-2 ml-8 mr-10'>
                <label className='text-[#5E6475] text-sm'>Confirmar senha</label>
                <div className='flex flex-row px-4 py-2 mt-2 bg-[#F7F8FA] border-2 focus-within:bg-white focus-within:border-2 focus-within:border-black rounded'>
                  <input 
                    type={toggleVisibilityConfirmNew ? 'password' : 'text'}
                    value={inputConfirmPassword}
                    onChange={(e) => setInputConfirmPassword(e.target.value)}
                    className='w-full text-sm bg-[#F7F8FA] focus:bg-white outline-none' 
                  />
                  <span onClick={event => setToggleVisibilityConfirmNew(!toggleVisibilityConfirmNew)} className='w-6 h-4'>
                    <img src={closedEyeImg} alt='Imagem de um olho fechado' />  
                  </span>
                </div>
              </div>
              <div className='relative'>
                <button onClick={handleCloseContactInfo} className='py-2 px-4 ml-8 mt-11 mb-7 rounded'>
                  <span className='text-[#404554] font-bold text-sm'>
                    Descartar alterações
                  </span>
                </button>
                <button onClick={handleSaveChanges} className='absolute bottom-7 right-8 py-2 px-4 bg-[#083CA6] rounded'>
                  <span className='text-white font-bold text-sm'>
                      Salvar
                  </span>
                </button>
              </div>
            </div>
          </section>
        : null
      }
    </>
  );
};