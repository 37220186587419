import React from 'react'
import ReactDOM from 'react-dom/client'
import "@govbr-ds/core/dist/core.min.css";

import App from './App'

import './global.css'
import './assets/style/signin.css'

document.title = 'Login'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
