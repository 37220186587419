import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { EditContactInfo } from '../components/Profile/EditContactInfo';
import { EditPassword } from '../components/Profile/EditPassword';
import { MainHeader } from '../components/Header';
import { Bread } from '../components/Bread/bread';
import { Alert } from '../components/Alert';

import { getLoggedUserData } from '../api/user';

import pen from '../assets/icons/pen.svg';

document.title = 'Profile';

export function Profile() {
  const [cpf, setCPF] = useState('');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [birthday, setBirthday] = useState('');
  const [organ, setOrgan] = useState('');
  const [ddd, setDDD] = useState('');
  const [phone, setPhone] = useState('');
  const [warName, setWarName] = useState('');
  const [userFunction, setUserFunction] = useState('');
  const [userLevel, setUserLevel] = useState('');

  const [isEditContactOn, setIsEditContactOn] = useState(false);
  const [isEditPasswordOn, setIsEditPasswordOn] = useState(false);
  const [user, setUser] = useState<any>({});
  const { state } : any = useLocation();
  
  useEffect(() => {
    async function fetchApi() {
      const userData = await getLoggedUserData();
      setUser(userData);
      console.log(userData);
    }

    fetchApi().catch(err => {
      Alert({
        type: 'error',
        message: 'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
        autoClose: true,
      });
    });
  }, []);

  useEffect(() => {
    setName(user.nome);
    setEmail(user.email);
    setBirthday(user.data_nascimento);
    setOrgan(user.organ);
    setDDD(user.ddd_celular_usuario);
    setPhone(user.celular);
    setWarName(user.nomeguerra);
    setUserLevel(user.nivel_usuario);
    setUserFunction(user.funcao_usuario);
    setCPF(user.cpf);
  }, [user]);


  const handleEditContact = () => {
    setIsEditContactOn(!isEditContactOn);
  };

  const handleEditPassword = () => {
    setIsEditPasswordOn(!isEditPasswordOn);
  };

  return (
    <div className='h-screen lg:h-fit w-screen bg-[#F7F8FA] flex mediumScreen:h-screen mediumScreen:block'>
      <EditContactInfo hidden={isEditContactOn} setHidden={handleEditContact} />
      <EditPassword hidden={isEditPasswordOn} setHidden={handleEditPassword} />

      <div className='h-fit mediumScreen:h-auto w-screen mb-10'>
        <MainHeader username={state.username} subtitles={['Gerenciar perfil']} />
        <Bread texto={'Gerenciamento de perfil'} nome={name} />
        <div className='w-full md:w-10/12 2xl:w-9/12 h-full lg:ml-28 md:ml-14 flex flex-col items-start p-6 bg-white mt-8'>
          <h1 className='text-xl leading-[1.2] font-bold md:text-2xl md:font-medium'>Gerenciar Perfil</h1>
          <p className='text-sm leading-[1.7] mt-2 md:text-xl md:font-medium'>Visualize as informações de cadastro e gerencie as informações de contato relacionadas à sua conta.</p>

          <div className='flex flex-col md:flex-row w-full'>
            <section className='mt-8 w-[calc(100vw-50%)] order-2 md:-order-none'>
              <h2 className='text-xl1 font-bold pb-6 shadow-b2'>Informações de Cadastro</h2>
              <p className='flex flex-col'>
                <label className='mt-6 text-profile font-semibold'>Nome</label>
                <span className='mt-2 text-profile'>{name}</span>
              </p>
              <div className='flex flex-row mt-12'>
                <p className='flex flex-col'>
                  <label className='mt-6 text-profile font-semibold'>CPF</label>
                  <span className='mt-2 text-profile'>{cpf}</span>
                </p>
                <p className='flex flex-col ml-12'>
                  <label className='mt-6 text-profile font-semibold'>Data de Nascimento</label>
                  <span className='mt-2 text-profile'>{birthday}</span>
                </p>
                <p className='flex flex-col ml-12'>
                  <label className='mt-6 text-profile font-semibold'>Órgão</label>
                  <span className='mt-2 text-profile'>{organ}</span>
                </p>
              </div>
            </section>
            <section className='bg-[#F7F8FA] lg:w-96 mt-8 md:ml-8 order-1 md:-order-none'>
              <div className='flex flex-col items-start ml-6 mt-6'>
                <label className='text-profile font-semibold'>Status de usuário</label>
                <div className='flex flex-row bg-[#F4FDF4] mt-2 items-center justify-center py-1 px-3'>
                  <span className='w-2 h-2 text-profile bg-[#2B9127] rounded-full'></span>  
                  <span className='text-profile text-green-600 ml-2'>Ativo</span>
                </div>
              </div>
              <div className='flex flex-row'>
                <p className='flex flex-col items-start ml-6 mt-6'>
                  <label className='text-profile font-semibold'>Função de usuário</label>
                  <span className='mt-2 text-profile'>{userFunction}</span>
                </p>
                <p className='flex flex-col items-start ml-6 mt-6'>
                  <label className='text-profile font-semibold'>Nível de usuário</label>
                  <span className='mt-2 text-profile'>{userLevel}</span>
                </p>
              </div>
              <div onClick={handleEditPassword} className='flex flex-row items-center justify-center mx-6 mt-4 mb-6 py-3 bg-white border-x border-y border-solid border-[#DBDDE5] cursor-pointer'>
                <img src={pen} alt='Imagem de uma caneta' />
                <button className='ml-3 rounded text-[#083CA6] text-sm font-bold'>Alterar senha</button>
              </div>
            </section>
          </div>
          <section className='h-full mt-10'>
            <div className='flex flex-row items-center'>
              <h2 className='text-xl1 font-bold pb-3'>Informações de contato</h2>
              
              <div onClick={handleEditContact} className='flex flex-row px-6 mt-4 mb-6 ml-8 py-3 bg-white border-x border-y border-solid border-[#DBDDE5] cursor-pointer'>
                <img src={pen} alt='Imagem de uma caneta' />
                <button className='ml-3 rounded text-[#083CA6] text-sm font-bold'>Editar informações</button>
              </div>
            </div>
            <span className='flex flex-row flex-1 h-px w-full mt-4 bg-[#EDEEF2]'></span>
            <div className='flex flex-row mt-6 mb-6 lg:mt-12 lg:mb-12'>
                <p className='flex flex-col'>
                  <label className='mt-6 text-profile font-semibold'>DDD do celular</label>
                  <span className='mt-2 text-profile'>{ddd}</span>
                </p>
                <p className='flex flex-col ml-8'>
                  <label className='mt-6 text-profile font-semibold'>Celular</label>
                  <span className='mt-2 text-profile'>{phone}</span>
                </p>
                <p className='flex flex-col ml-8'>
                  <label className='mt-6 text-profile font-semibold'>E-mail</label>
                  <span className='mt-2 text-profile'>{email}</span>
                </p>
                <p className='flex flex-col ml-8'>
                  <label className='mt-6 text-profile font-semibold'>Nome de guerra</label>
                  <span className='mt-2 text-profile'>{warName}</span>
                </p>
              </div>
          </section>
        </div>
      </div>
    </div>
  );
};