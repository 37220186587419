import { MainHeader } from "../../components/Header";
import { Bread } from "../../components/Bread/bread";
import { TableManageClasses } from "./components/TableManageClasses";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import TopCardScholls from "./components/TopCardScholls";

export default function ManageClasses() {
  document.title = "Gerenciar Turmas";

  return (
    <>
      <MainHeader
        username={"Redux?"}
        subtitles={["Gerenciamento de turmas e alunos"]}
      />
      <Bread texto={"Gerenciamento de turmas e alunos"} nome={"Redux?"} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          padding: "24px 0 32px 0",
          background: "#f2f5fd",
          "@media (min-width: 768px)": {
            width: "100%",
          },
        }}
      >
        <Box sx={{ marginTop: "21px" }}>
          <TopCardScholls />
          <TableManageClasses />
        </Box>
      </Box>
    </>
  );
}
