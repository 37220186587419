/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, CSSProperties } from 'react'
import { useLocation } from 'react-router'

import { Bread } from '../../components/Bread/bread'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import GroupIcon from '@mui/icons-material/Group'
import AccountBoxIcon from '@mui/icons-material/AccountBox'

import { getUsersData } from '../../api/user'
import { getProfilesData } from '../../api/profile'

import { Alert } from '../../components/Alert'
import { MainHeader } from '../../components/Header'
import { CardManagement } from './components/CardManagement'

import {
  CardRowData,
  CardRowDataProfile,
} from './components/CardManagement/index'

import { AddUser } from './Users/AddUser'
import { EditUser } from './Users/EditUser'
import DeleteUser from './Users/DeleteUser'

import { DeleteProfile } from './Profiles/DeleteProfile'
import { EditProfile } from './Profiles/EditProfile'
import { handleFirstName } from '../../utils/string'
import { AddProfile } from './Profiles/AddProfile'

interface CardRowDataUser {
  id: string
  name: string
  cpf?: string
  email?: string
  password?: string
  accessSystems: Array<string>
  inclusionDate: string
  status: boolean
  statusAction: (user: any) => Promise<any>
  editAction: (user: any) => Promise<any>
  deleteAction: (user: any) => Promise<any>
}

interface ManagementProps {
  username: string
  state?: any
}

export const statusUserAction = async (user: any) => {
  return true
}

export const editUserAction = async (user: any) => {
  const response: any = await EditUser(user.id, user.name, user.email, user.profiles)
  
  if (response) {
    Alert({
      type: response.changed || response.deleted ? 'success' : 'error',
      message:  response.changed || response.deleted ? 'Usuário editado com sucesso.' : 'Ops! Ocorreu um erro ao salvar as alterações. Tente novamente mais tarde.',
      autoClose: true,
    })
  }

  return response
}

export const deleteUserAction = async (user: any) => {
  const response: any = await DeleteUser(user.id, user.name)

  if (response) {
    Alert({
      type: response.changed ? 'success' : 'error',
      message:  response.changed ? 'Usuário excluído com sucesso.' : 'Ops! Ocorreu um erro ao salvar as alterações. Tente novamente mais tarde.',
      autoClose: true,
    })
  }

  return response
}

export const editProfileAction = async (row: any) => {
  const response: any = await EditProfile(row.id, row.name)

  if (response) {
    Alert({
      type: response.changed ? 'success' : 'error',
      message: response.changed ? 'Informações de perfil de usuário alteradas com sucesso.' : 'Ops! Ocorreu um erro ao salvar as alterações. Tente novamente mais tarde.',
      autoClose: true,
    })
  }

  return response
}

export const deleteProfileAction = async (row: any) => {
  const response: any = await DeleteProfile(row.id, row.name)

  if (response) {
    Alert({
      type: response.changed ? 'success' : 'error',
      message: response.changed ? 'Perfil de usuário excluído com sucesso.' : 'Ops! Ocorreu um erro ao salvar as alterações. Tente novamente mais tarde.',
      autoClose: true,
    })
  }

  return response
}

export function Management({ username }: ManagementProps | any) {
  const [tab, setTab] = useState(0)
  const [tabs] = useState([
    {
      id: 0,
      label: 'Usuários',
      tabMargin: 30,
      tabLength: 120,
      icon: (style: CSSProperties) => <GroupIcon style={style} />,
    },
    {
      id: 1,
      label: 'Perfis de usuário',
      tabMargin: 30,
      tabLength: 200,
      icon: (style: CSSProperties) => <AccountBoxIcon style={style} />,
    },
  ])

  const [users, setUsers] = useState([])
  const [profiles, setProfiles] = useState<CardRowDataProfile[]>([])
  const [userName, setUserName] = useState('')
  const { state }: any = useLocation()

  /* Load data */
  useEffect(() => {
    document.title = 'Gerenciamento de usuários'

    getUsersData().then((data) => {
      let newUsers: any = Array(data.count);
      newUsers = data.results.map((user: any) => {
        return {
          id: user.cpf,
          name: user.nome_usuario,
          email: user.email,
          accessSystems: ['SAP'],
          profiles: user.perfil,
          inclusionDate: user.data_criacao,
          status: user.active,
          statusAction: statusUserAction,
          editAction: editUserAction,
          deleteAction: deleteUserAction,
        }
      })
      
      setUsers(newUsers)
    }).catch((error) => {
      Alert({
        type: 'error',
        message: 'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
        autoClose: true,
      })
    })

    getProfilesData().then((data) => {
      const newProfiles = data.results.map((profile: any) => {
        
        return {
          id: profile.id_perfil.toString(),
          name: profile.descricao_perfil,
          accessSystems: ['SAP'],
          profiles: [],
          inclusionDate: '01/01/2020',
          editAction: editProfileAction,
          deleteAction: deleteProfileAction,
        }
      })

      setProfiles(newProfiles)
    }).catch((error) => {
      Alert({
        type: 'error',
        message: 'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
        autoClose: true,
      })
    })
    
  }, [])

  useEffect(() => {
    setUserName(handleFirstName(username))
  }, [username])

  /* Functions */
  const handleTabChange = (newValue: number) => {
    setTab(newValue)
  }

  const getWidth = (tab: number) => {
    return tabs.find((item: any) => item.id === tab)?.tabLength
  }

  const calculateMargin = (tab: number) => {
    if (tab === 0) {
      return 0
    } else {
      const index = tabs.findIndex((item: any) => item.id === tab)

      return tabs
        .slice(0, index)
        .reduce((accumulator: number, currentValue: any) => {
          return accumulator + currentValue.tabLength + currentValue.tabMargin
        }, 0)
    }
  }

  return (
    <>
      <MainHeader username={state.name} subtitles={['Gerenciamento de usuários']} />
      <Bread texto={'Gerenciamento de usuários'} nome={state.name}/>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '24px 0 32px 0',
          background: '#f2f5fd',
          '@media (min-width: 768px)': {
            width: '100%',
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '90%',
            marginBottom: '18px',
          }}
        >
          {tabs.map((currentTab, index) => (
            <Button
              key={`tab-${index}`}
              variant='text'
              startIcon={currentTab.icon({
                width: '24px',
                height: '24px',
              })}
              style={{
                color: tab === currentTab.id ? '#083CA6' : '#000',
                fontSize: '1rem',
                fontWeight: 600,
                textTransform: 'none',
                padding: '5px 10px',
                marginRight: `${currentTab.tabMargin}px`,
                width: currentTab.tabLength,
              }}
              onClick={() => handleTabChange(currentTab.id)}
            >
              {currentTab.label}
            </Button>
          ))}
        </Box>
        <Box
          sx={{
            height: '6px',
            width: '90%',
            borderRadius: '6px',
            background: '#B6C4EE',
          }}
        >
          <Box
            sx={{
              height: '6px',
              width: `${getWidth(tab)}px`,
              borderRadius: '6px',
              marginLeft: `${calculateMargin(tab)}px`,
              transition: 'all 0.3s ease-in-out',
              background: '#083CA6',
            }}
          ></Box>
        </Box>
        <Box sx={{ marginTop: '21px' }}>
          {tab === 0 && (
            <CardManagement
              profile={false}
              buttonAction={async () => AddUser()}
              data={users}
            />
          )}
          {tab === 1 && (
            <CardManagement
              profile={true}
              buttonAction={async () => AddProfile()}
              data={profiles}
            />
          )}
        </Box>
      </Box>
    </>
  )
}
