import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { CircleNotch, IdentificationCard } from 'phosphor-react';

import { userRegister } from '../api/user';
import { refreshToken } from '../api/token';
import { cpfMask } from '../utils/string';
import { InvalidInput } from '../components/InvalidInput';

import keyImg from '../assets/icons/key.svg';
import eyeImg from '../assets/icons/eye.svg';
import profile from '../assets/icons/grayProfile.svg';
import email from '../assets/icons/email.svg';

document.title = 'Register';

export function Register() {
  const [cpfInput, setCpfInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [confirmPasswordInput, setConfirmPasswordInput] = useState('');
  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');

  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);

  const [toggleVisibility, setToggleVisibility] = useState(true);
  const [toggleVisibilityConfirm, setToggleVisibilityConfirm] = useState(true);

  const navigate = useNavigate();

  function checkNull() {
    if (
      cpfInput === '' || 
      passwordInput === '' ||
      confirmPasswordInput === '' ||
      nameInput === '' ||
      emailInput === ''
    ) {
      return true;
    }
    return false;
  };

  async function handleRegister(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);

    if(checkNull()) {
      alert('Preencha todos os campos');
      setIsLoading(false);
      return;
    }

    await refreshToken();

    const registerResult = await userRegister({
      cpf: cpfInput,
      email: emailInput,
      username: nameInput,
      password: passwordInput,
    });

    if (registerResult) {
      navigate('/home');
    }
    
    setIsLoading(false);
  };

  const handleCPF = (event:React.ChangeEvent<HTMLInputElement>) => {
    let CPF = event.target.value;
    setCpfInput(cpfMask(CPF));
  };

  const handleOnVerify = (value:string|null) => {
    if(value?.length !== 0) {
      setIsVerified(!isVerified);
    }
  };

  const emailValidation = (email:string) => {
    setEmailInput(email);

    if(email.length === 0) {
      setInvalidEmail(false);
      return 
    }

    const valid : boolean = !!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    setInvalidEmail(!valid);
  };

  const passwordValidation = (password:string) => {
    setPasswordInput(password);

    if(password.length === 0) {
      setInvalidPassword(false);
      return 
    }

    const valid : boolean = !!password.match(/(?=.*?[0-9]).{6,}$/);
    setInvalidPassword(!valid);
  };

  const setColorNameInput = () => {
    if(nameInput !== '') {
      return 'border-[#168821]';
    }

    return 'border-[#888888]';
  };

  const setColorCpfInput = () => {
    if(cpfInput.length === 14) {
      return 'border-[#168821]';
    }

    return 'border-[#888888]';
  };

  const setColorEmailInput = () => {
    if (invalidEmail && emailInput !== '') {
      return 'border-[#E52207]';
    } else if(!invalidEmail && emailInput !== '') {
      return 'border-[#168821]';
    }
    
    return 'border-[#888888]';
  };
  
  const setColorPasswordInput = () => {
    if(invalidPassword && passwordInput !== '') {
      return 'border-[#E52207]';
    } else if(!invalidPassword && passwordInput !== '') {
      return 'border-[#168821]';
    }
    
    return 'border-[#888888]';
  }

  const setColorConfirmPasswordInput = () => {
    if(confirmPasswordInput !== passwordInput) {
      return 'border-[#E52207]';
    } else if(confirmPasswordInput === passwordInput && confirmPasswordInput !== '') {
      return 'border-[#168821]';
    }

    return 'border-[#888888]';
  }

  const resetForm = () => {
    setNameInput('');
    setCpfInput('');
    setEmailInput('');
    setPasswordInput('');
    setConfirmPasswordInput('');
  }

  return (
    <div className='flex justify-center items-center w-[calc(100vw-2rem)] md:w-[auto]'>
      {
        isLoading ? 
          <div className='w-screen h-screen z-50 flex items-center justify-center overflow-hidden absolute bg-black bg-opacity-80'>
            <CircleNotch weight='regular' color='#CCCCCC' className='w-20 h-20 animate-spin' />
          </div> 
        : null 
      }
      <form onSubmit={handleRegister} className='flex flex-col max-w-[500px] h-auto w-500px items-start p-0 gap-4 relative shadow-sm box-border'>
        <label className='ml-3 mt-4 text-xl font-bold'>Cadastrar-se</label>
        <div id='name_field' className='flex flex-col ml-4 mt-2'>
          <label className='font-semibold text-input w-auto h-[19px]' >
            Nome completo
          </label>
          
          <div className={`w-468px h-10 items-center self-stretch flex flex-row mt-1 py-1 pr-1 border-x border-y ${setColorNameInput()} rounded bg-no-repeat`}>
            <span className='ml-4 w-5 mr-2' >
              <img src={profile} alt='Imagem de uma pessoa' />
            </span>

            <input 
              id='name_input'
              type='text'
              name='name'
              onChange={(event) =>setNameInput(event.target.value)}
              value={nameInput}
              placeholder='Ex. Maria Antônia Sousa'
              className='placeholder:italic placeholder:text-input'
              style={{all: 'unset', width:'100%'}}
              autoComplete='on'
            />
          </div>
        </div>

        <div id='cpf_field' className='flex flex-col ml-4 mt-2'>
          <label className='font-semibold text-input w-[43px] h-[19px]' >
            CPF
          </label>
          
          <div className={`w-468px h-10 items-center self-stretch flex flex-row mt-1 py-1 pr-1 border-x border-y ${setColorCpfInput()} rounded bg-no-repeat`}>
            <span className='ml-[14px] w-5 mr-2' >
              <IdentificationCard weight='fill' color='#888888' size={'20px'} />
            </span>

            <input 
              id='cpf_input'
              type='text'
              name='cpf'
              onChange={handleCPF}
              value={cpfInput}
              placeholder='000.000.00-00'
              className='placeholder:italic placeholder:text-input'
              style={{all: 'unset', width:'100%'}}
              autoComplete='on'
            />
          </div>
        </div>

        <div id='email_field' className='flex flex-col ml-4 mt-2'>
          <label className='font-semibold text-input w-[43px] h-[19px]' >
            E-mail
          </label>
          
          <div className={`w-468px h-10 items-center self-stretch flex flex-row mt-1 py-1 pr-1 border-x border-y ${setColorEmailInput()} rounded bg-no-repeat`}>
            <span className='ml-4 w-5 mr-2' >
              <img src={email} alt='Imagem de uma carta' />
            </span>

            <input 
              id='email_input'
              type='text'
              name='cpf'
              onChange={(event) =>emailValidation(event.target.value)}
              value={emailInput}
              placeholder='nome@email.com'
              className='placeholder:italic placeholder:text-input'
              style={{all: 'unset', width:'100%'}}
              autoComplete='on'
            />
          </div>

          <InvalidInput text='E-mail inválido' hidden={invalidEmail}/>
        </div>
      
        <div id='password_field' className='flex flex-col ml-4 mt-2 relative'>
          <label className='font-semibold text-input w-[43px] h-[19px]'>
            Senha
          </label>

          <div className={`w-468px h-10 mt-1 items-center self-stretch flex flex-row border-x border-y ${setColorPasswordInput()} rounded`}>
            <span className='ml-4 w-5 mr-2'>
              <img src={keyImg} alt='Imagem de uma chave'/>
            </span>

            <input 
              id='password_input'
              type={toggleVisibility ? 'password' : 'text'}
              name='Senha'
              onChange={ event => passwordValidation(event.target.value) }
              value={passwordInput}
              style={{all:'unset', width:'100%'}}
              autoComplete='off'
            />

            <span onClick={event => setToggleVisibility(!toggleVisibility)} className='w-6 h-3 mr-2'>
              <img src={eyeImg} alt='Imagem de um olho'/>
            </span>
          </div>

          <InvalidInput text='Deve conter ao menos 6 caracteres' hidden={invalidPassword}/>

          <label className='text-base mt-3'>
            Mínimo de 6 caracteres, deve conter ao menos um número
          </label>
        </div>

        <div id='confirm_password_field' className='flex flex-col ml-4 mt-2 relative'>
          <label className='font-semibold text-input w-auto h-[19px]'>
            Confirmar senha
          </label>

          <div className={`w-468px h-10 mt-1 items-center self-stretch flex flex-row border-x border-y ${setColorConfirmPasswordInput()} rounded`}>
            <span className='ml-4 w-5 mr-2'>
              <img src={keyImg} alt='Imagem de uma chave'/>
            </span>

            <input 
              id='confirm_password_input'
              type={toggleVisibilityConfirm ? 'password' : 'text'}
              name='Senha'
              onChange={ event => setConfirmPasswordInput(event.target.value) }
              value={confirmPasswordInput}
              style={{all:'unset', width:'100%'}}
              autoComplete='off'
            />

            <span onClick={event => setToggleVisibilityConfirm(!toggleVisibilityConfirm)} className='w-6 h-3 mr-2'>
              <img src={eyeImg} alt='Imagem de um olho'/>
            </span>
          </div>
        </div>

        <div className='ml-4 mt-4 mb-40 p-0'>
          <ReCAPTCHA
            sitekey='6LftfA0gAAAAAPRo62XfYZ465q3pvgG1aNOrwaU_'
            onChange={value => handleOnVerify(value)}
          />

          <p className='text-base'>
            Ao se cadastrar, você está aceitando os Termos de Uso e de Política de Privacidade. <a href='/register' className='text-blue-warm-vivid-70 font-bold'> Ler Termos </a>
          </p>
        </div>


        <button type='reset' value='reset' onClick={resetForm} className='flex flex-col justify-center items-center absolute bottom-3 right-32 px-6 py-2 gap-2 w-[7.5rem] h-8 rounded-3xl border-x border-y border-[#1351B4] text-[#1351B4]'>
          Cancelar
        </button>
        <button 
          type='submit'
          disabled={!isVerified} 
          className='flex flex-col justify-center items-center bg-[#1351B4] text-white absolute bottom-3 right-4 px-6 py-2 gap-2 w-[6rem] h-8 rounded-3xl disabled:text-opacity-50'
        >
          Cadastrar
        </button>
      </form>
      
    </div>
  );
};