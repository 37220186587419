import axios from 'axios';

import { getLocalToken, setLocalToken } from "../services/localStorage";

const api = axios.create({
  baseURL: 'https://scanner-prova.rj.r.appspot.com/'
});

export const refreshToken = async () => {
  const token = getLocalToken();
  return await api.post('govbr/user/token/refresh/', {
    refresh: token
  }).then((res) => {
    const success = res.status === 200;
    
    if (success) {
      setLocalToken(res.data.access);
    }

    return success;
  }).catch(() => {
    return false;
  });
}