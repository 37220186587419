import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';

import { handleFirstName } from '../utils/string';

import arrowDown from '../assets/icons/arrowDown.svg';
import profileIcon from '../assets/icons/profile.svg';

interface DropdownProps {
  username: string;
}

export function Dropdown({ username } : DropdownProps) {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  };

  const handleSignOut = () => {
    navigate('/logout');
  };

  const toProfile = () => navigate('/profile', { state: { username } });

  useEffect(() => {
    setUserName(handleFirstName(username));
  }, [username]);

  return (
    <Menu as="div" className="relative inline-block text-left ml-[10%]">
      <div>
        <Menu.Button className="flex justify-center items-center py-2 px-3 bg-[#083CA6] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-warm-vivid-70 rounded-full">
          <img src={profileIcon} alt="Ícone do busto de uma pessoa" className="w-4 h-4" />
          <p className="text-white text-profile ml-3 font-medium hidden md:text-sm md:block">{userName}</p>
          <img src={arrowDown} alt="Ícone de seta" className="w-4 h-4 sm:ml-3 ml-1"/>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div>
            <Menu.Item>
              {({ active }) => (
                <div onClick={toProfile} className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'w-full text-left py-2 text-sm rounded flex flex-row justify-start items-center cursor-pointer'
                )}>
                  <svg className='ml-5' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 9.33333C2 8 4.66667 7.26667 6 7.26667C7.33333 7.26667 10 8 10 9.33333V10H2V9.33333ZM8 4C8 4.53043 7.78929 5.03914 7.41421 5.41421C7.03914 5.78929 6.53043 6 6 6C5.46957 6 4.96086 5.78929 4.58579 5.41421C4.21071 5.03914 4 4.53043 4 4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2C6.53043 2 7.03914 2.21071 7.41421 2.58579C7.78929 2.96086 8 3.46957 8 4ZM0 1.33333V10.6667C0 11.0203 0.140476 11.3594 0.390524 11.6095C0.640573 11.8595 0.979711 12 1.33333 12H10.6667C11.0203 12 11.3594 11.8595 11.6095 11.6095C11.8595 11.3594 12 11.0203 12 10.6667V1.33333C12 0.979711 11.8595 0.640573 11.6095 0.390524C11.3594 0.140476 11.0203 0 10.6667 0H1.33333C0.593333 0 0 0.6 0 1.33333Z" fill="black"/>
                  </svg>
                  <button
                    type="button"
                    
                    className='ml-3'
                  >
                    Gerenciar Perfil
                  </button>
                </div>
              )}
            </Menu.Item>
          </div>
          <div>
            <Menu.Item>
              {({ active }) => (
                <div onClick={handleSignOut} className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'w-full text-left py-2 text-sm rounded flex flex-row justify-start items-center cursor-pointer'
                )}>
                  <svg className='ml-5' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.6667 0H1.33333C0.593333 0 0 0.593333 0 1.33333V4H1.33333V1.33333H10.6667V10.6667H1.33333V8H0V10.6667C0 11.0203 0.140476 11.3594 0.390524 11.6095C0.640573 11.8595 0.979711 12 1.33333 12H10.6667C11.0203 12 11.3594 11.8595 11.6095 11.6095C11.8595 11.3594 12 11.0203 12 10.6667V1.33333C12 0.593333 11.4 0 10.6667 0ZM4.72 8.38667L5.66667 9.33333L9 6L5.66667 2.66667L4.72 3.60667L6.44667 5.33333H0V6.66667H6.44667L4.72 8.38667Z" fill="black"/>
                  </svg>

                  <button
                    type="button"
                    className='ml-3'
                  >
                    Sair
                  </button>
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};