import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '16px',
  gap: '16px',

  position: 'absolute',

  background: '#FFFFFF',
  /* dropshadow_apa */

  boxShadow: '0px 0px 24px rgba(106, 115, 151, 0.06)',
  borderRadius: '6px',
}

type Props = {
  children: JSX.Element | JSX.Element[] | string | string[]
  title: string
  open: boolean
  close: (value: boolean) => void
  onSubmit?: any
  buttons?: boolean
  cancelBtn?: string
  confirmBtn?: string
  confirmBtnColor?: any
}

export default function BasicModal(props: Props) {
  const [open, setOpen] = React.useState(false)
  // const handleOpen = () => setOpen(true)
  const handleClose = () => {
    props.close(false)
    setOpen(false)
  }

  const onSubmit = () => {
    props.onSubmit()
  }

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='flex justify-center items-center'
      >
        <Box sx={style} className='w-[664px]'>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '16px',
              width: '632px',
              height: '62px',
              background: '#FFFFFF',
              flex: 'none',
              order: 0,
              alignSelf: 'stretch',
              flexGrow: 0,
            }}
            className='justify-between	'
          >
            <div
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '20px',
                lineHeight: '150%',
              }}
            >
              {props.title}
            </div>

            <CloseIcon
              onClick={() => handleClose()}
              sx={{
                width: '24px',
                height: '24px',
                color: 'black',
                padding: '0px',
                margin: '0px',
                cursor: 'pointer',
              }}
            />
          </Box>
          {props.children}

          {/* default buttons */}
          <Box
            sx={{
              display: props.buttons ? 'flex' : 'none',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px 16px',
              gap: '16px',

              width: '632px',
              height: '64px',

              /* Pure/.pure */

              background: '#FFFFFF',

              /* Inside auto layout */

              flex: 'none',
              order: 2,
              flexGrow: 0,
            }}
          >
            <Button
              color='inherit'
              onClick={handleClose}
              sx={{
                mr: 1,
                display: 'flex',

                flexDirection: 'row',
                alignItems: 'center',
                padding: '8px 16px',
                gap: '8px',

                width: '74px',
                height: '40px',

                borderRadius: '4px',

                /* Inside auto layout */

                flex: 'none',
                order: 0,
                flexGrow: 0,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '100%',
                textTransform: 'none',
              }}
            >
              {props.cancelBtn ? props.cancelBtn : 'Cancelar'}
            </Button>

            <Button
              variant='contained'
              color={props.confirmBtnColor ? props.confirmBtnColor : 'primary'}
              onClick={onSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '8px 16px',
                gap: '8px',

                height: '40px',

                borderRadius: '4px',

                flex: 'none',
                order: 0,
                flexGrow: 0,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '100%',
                textTransform: 'none',
                color: 'white',
              }}
            >
              {props.confirmBtn ? props.confirmBtn : 'Salvar alterações'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
