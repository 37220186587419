import { MainHeader } from "../../components/Header";
import { Bread } from "../../components/Bread/bread";
import { TableManageStudents } from "./components/TableManageStudents";

import Box from "@mui/material/Box";
import TopCardScholls from "../../components/TopCardScholls";

export default function ManageSchool() {
  document.title = "Gerenciar alunos";
  return (
    <>
      <MainHeader
        username={"Redux?"}
        subtitles={["Gerenciamento de escolas"]}
      />
      <Bread texto={"Gerenciamento de escolas"} nome={"Redux?"} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          padding: "24px 0 32px 0",
          background: "#f2f5fd",
          "@media (min-width: 768px)": {
            width: "100%",
          },
        }}
      >
        <Box sx={{ marginTop: "21px" }}>
          <TopCardScholls />
          <TableManageStudents />
        </Box>
      </Box>
    </>
  );
}
