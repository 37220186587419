import httpScanner from '../http/scanner-prova'

export default function AuthGovService() {
  const getUrl = async (queryParams: object) => {
    const api = await httpScanner.post('/govbr/authorize/', queryParams)
    return api
  }

  const getAuthTokens = async (queryParams: object) => {
    const api = await httpScanner.post('/govbr/', queryParams)
    return api
  }

  const getUserData = async () => {
    const api = await httpScanner.get('/govbr/user/info/1/')
    return api
  }

  return {
    getUrl,
    getAuthTokens,
    getUserData,
  }
}
