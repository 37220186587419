import axios from 'axios';

import { getLocalToken } from "../services/localStorage";

const api = axios.create({
  baseURL: 'https://scanner-prova.rj.r.appspot.com/'
});

export const getSchoolsData = async () => {
  const token = getLocalToken();
  const res = await api.get('govbr/escola/', {
    headers:{
      'Authorization': `Bearer ${token}`
    }
  });

  return res.data;
}

export const getClassesData = async () => {
  const token = getLocalToken();
  const res = await api.get('govbr/turma/', {
    headers:{
      'Authorization': `Bearer ${token}`
    }
  });

  return res.data;
}