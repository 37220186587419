import {
  Fragment,
  useEffect,
  useState,
} from 'react'
import { createRoot } from 'react-dom/client'

import { Dialog, Transition } from '@headlessui/react'

import { Alert } from '../../../components/Alert';

import closeIcon from '../../../assets/icons/blackCloseIcon.svg'

import { getSystemsData } from '../../../api/system'
import { linkUserToProfile } from '../../../api/profile'
import { getClassesData, getSchoolsData } from '../../../api/school'
import { getCustom, getLoggedUserData } from '../../../api/user'

import { brazilStates } from '../../../utils/states'

interface systemProps {
  id: number
  name: string
}

export function LinkUser(name: string, userId: string) {
  return new Promise((resolve, reject) => {
    addDialog(resolve, name, userId);
  });
}

function removeDialog(root: any) {
  root.unmount();

  const div = document.getElementById('modal-link-user');
  if (div) {
    div.remove();
  }
}

function addDialog(resolve: any, name: string, userId: string) {
  const body = document.getElementsByTagName('body')[0];
  const div = document.createElement('div');

  div.setAttribute('id', 'modal-link-user');
  body.appendChild(div);

  const root = createRoot(div);

  root.render(
    <CreateDialog 
      root={root} 
      resolve={resolve}
      name={name}
      userId={userId}
    />
  );
}

function CreateDialog(dialogObj: { root: any, resolve: any, name: string, userId: string}) {
  const [cpf, setCpf] = useState('');
  const [isLinkUserInfoOpen] = useState(true);

  const [system, setSystem] = useState('');
  const [systemsData, setSystemsData] = useState<systemProps[]>([
    { id: 0, name: 'Selecione o sistema' },
  ]);

  const [schools, setSchools] = useState('1')
  const [schoolsData, setSchoolsData] = useState([]);

  const [profiles, setProfiles] = useState('');

  const [classes, setClasses] = useState('')
  const [classesData, setClassesData] = useState([]);

  const [profilesAndSchoolsData, setProfilesAndSchoolsData] = useState<any>([]);

  const [currentState, setCurrentState] = useState('');
  const [currentCity, setCurrentCity] = useState('');

  const handleSetSchools = () => {
    const selectElement: any = document.getElementById('schools');
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    
    setSchools(selectedOption.value);
  }

  const handleSetClasses = () => {
    const selectElement: any = document.getElementById('classes');
    const selectedOption = selectElement.options[selectElement.selectedIndex];

    setClasses(selectedOption.value);
  }

  const handleClose = () => {
    removeDialog(dialogObj.root);

    dialogObj.resolve();
  };

  const handleLink = () => {
    removeDialog(dialogObj.root);

    let classname: string = '';

    classesData.forEach((item: any) => {
      if (item.id.toString() === classes) {
        classname = item.name
      }
    })
    
    const studentData = {
      name: dialogObj.name,
      classId: classes,
      className: classname,
      id: dialogObj.userId,
    }

    linkUserToProfile({
      profileId: profiles,
      id: cpf, 
      userId: dialogObj.userId,
      schoolId: schools
    })
    .then((res: any) => {
      dialogObj.resolve({
        changed: profiles === '23' ? true : false,
        data: studentData,
      });
    })
    .catch((err: any) => {
      Alert({
        type: 'error',
        message: 'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
        autoClose: true,
      })

      dialogObj.resolve([{ changed: false }]);
    });
  };

  useEffect(() => {
    getCustom(dialogObj.userId).then((data) => {
      const newProfiles = data.map((item: any) => {
        const schools = item.escolas
        return {
          id: item.perfil.id_perfil,
          profile_name: item.perfil.descricao_perfil,
          hasAccess: item.perfil.acesso_escolas,
          schools,
        }
      });
  
      getSchoolsData().then((data) => {
        const schools = data.map((item: any) => {
          return {
            id: item.id_escola,
            name: item.nome_escola,
            state: item.estado,
            city: item.cidade
          }
        });
  
        setSchoolsData(schools);
      });
  
      setProfilesAndSchoolsData(newProfiles);
    });
  }, [dialogObj.userId]);

  useEffect(() => {
    getClassesData().then((data) => {
      const newClasses = data.map((classes: any) => {
        return {
          id: classes.id_turma,
          name: classes.nome_turma,
          schoolId: classes.id_escola,
        }
      })

      setClassesData(newClasses)
    })
  }, []);

  useEffect(() => {
    getSystemsData().then((data) => {
      const newSystems = data.map((system: any) => {
        return {
          id: system.id_modulo,
          name: system.nome_modulo,
        }
      })

      setSystemsData(newSystems)
    })
  }, []);

  useEffect(() => {
    getLoggedUserData().then((data: any) => {
      setCpf(data.cpf);
    })
  });

  return (
    <section>
      {isLinkUserInfoOpen ? (
        <Transition.Root show as='div'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-[#14161F]  bg-opacity-40 transition-opacity' />
          </Transition.Child>

          <Dialog
            as='div'
            className='w-full z-10 relative'
            onClose={handleClose}
            id='basic'
          >
            <div className='fixed z-10 inset-0 overflow-y-auto'>
              <div className='flex items-end sm:items-center justify-center min-h-full text-center sm:p-0'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  leave='ease-in duration-200'
                >
                  <Dialog.Panel className='relative w-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-screen-sm sm:w-full'>
                    <div className='bg-white'>
                      <div className='sm:flex sm:items-start'>
                        <div className='text-center sm:text-left w-full'>
                          <div className='flex flex-row justify-between mx-8 mt-8'>
                            <Dialog.Title
                              as='h3'
                              className='text-lg font-medium'
                            >
                              Vincular usuário
                            </Dialog.Title>
                            <img
                              id='close_user'
                              className='cursor-pointer'
                              src={closeIcon}
                              alt='Imagem de um x'
                              onClick={handleClose}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <form className='flex flex-col justify-start bg-white rounded-md mt-12 ml-8'>
                      <p className='text-profile mr-8'>
                        Defina a quais sistemas o usuário terá acesso, assim como qual a sua função em cada um deles:
                      </p>
                      <div className='border #EDEEF2 box-border flex-1 mt-4 mr-10 p-4'>
                        <div className='flex flex-col'>
                          <label className='text-[#5E6475] text-sm'>
                            Sistema
                          </label>
                          <select
                            className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]'
                            onChange={(e) => setSystem(e.target.value)}
                            value={system}
                            defaultValue='DEFAULT'
                          >
                            <option value="DEFAULT" className="">Selecione o sistema</option>
                            {systemsData.map(
                              (system: systemProps, index: React.Key) => {
                                return (
                                  <option
                                    key={index}
                                    className='text-[#9EA1B0]'
                                    value={system.id}
                                  >
                                    {system.name}
                                  </option>
                                )
                              }
                            )}
                          </select>
                        </div>

                        <div className='flex flex-col mt-2'>
                          <label className='text-[#5E6475] text-sm'>
                            Função do usuário
                          </label>
                          <select
                            className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]'
                            onChange={(e) => setProfiles(e.target.value)}
                            value={profiles}
                          >
                            <option value="DEFAULT" className="">Selecione a função do usuário</option>
                            {profilesAndSchoolsData.map(
                              (profile: any, index: React.Key) => {
                                return (
                                  <option
                                    key={index}
                                    className='text-[#9EA1B0]'
                                    value={profile.id}
                                  >
                                    {profile.profile_name}
                                  </option>
                                )
                              }
                            )}
                          </select>
                        </div>

                        <div className=" mt-2 flex flex-row justify-between relative">
                            <div className="flex flex-col flex-1">
                              <span className="text-sm text-[#5E6475]">Estado</span>
                              <select
                                id='states'
                                className='mt-2 px-4 py-2 text-user w-full bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]'
                                onChange={(e) => setCurrentState(e.target.value)}
                                value={currentState}
                                defaultValue='DEFAULT'
                              >
                                <option value="DEFAULT" className="text-sm">Selecione o estado</option>
                                {
                                  brazilStates.states.map((state, index) => {
                                    return <option key={index} value={state.initials}>{state.initials}</option>
                                  })
                                }
                              </select>
                            </div>

                            <div className="flex flex-col ml-6 flex-1">
                              <span className="text-sm text-[#5E6475]">Município</span>
                              <select
                                id='profile'
                                className='mt-2 px-4 py-2 text-user w-full bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]'
                                onChange={(e) => {setCurrentCity(e.target.value)}}
                                value={currentCity}
                                defaultValue='DEFAULT'
                              >
                                <option value="DEFAULT" className="text-sm">Selecione o município</option>
                                {
                                  brazilStates.states.map((state, index) => {
                                    return (
                                      state.initials === currentState ? 
                                        state.cities.map((city, idx) => {
                                          return <option key={idx} value={city}>{city}</option> 
                                        })
                                      : null
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>

                        <div className='flex flex-col mt-2'>
                          <label className='text-[#5E6475] text-sm'>
                            Escolas
                          </label>
                          <select
                            id='schools'
                            className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]'
                            onChange={(e) => handleSetSchools()}
                            value={schools}
                            defaultValue='DEFAULT'
                          >
                            <option value="DEFAULT" className="">Selecione a escola</option>
                            {profilesAndSchoolsData.map(
                              (profile: any, index: React.Key) => {
                                return profile.id.toString() === profiles &&
                                  profile.hasAccess ? (
                                  <option
                                    key={index}
                                    className='text-[#9EA1B0]'
                                    value={'1'}
                                  >
                                    Todas
                                  </option>
                                ) : null
                              }
                            )}

                            {profilesAndSchoolsData.map(
                              (profile: any, index: React.Key) => {
                                return profile.id.toString() === profiles &&
                                  !profile.hasAccess ? 
                                  schoolsData.map((school: any, idx: React.Key) => {
                                    return (
                                      currentState === school.state && currentCity.toUpperCase() === school.city ?
                                      <option
                                        key={idx}
                                        className='text-[#9EA1B0]'
                                        value={school.id}
                                      >
                                        {school.name}
                                      </option>
                                      : null
                                    )
                                  })
                                  : null
                              }
                            )}
                          </select>
                        </div>
                        {profiles === '23' ? (
                          <div className='flex flex-col mt-2'>
                            <label className='text-[#5E6475] text-sm'>
                              Turmas
                            </label>
                            <select
                              id='classes'
                              className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]'
                              onChange={(e) => handleSetClasses()}
                              value={classes}
                              defaultValue='DEFAULT'
                            >
                              <option value="DEFAULT" className="">Selecione a turma</option>
                              {classesData.map((clas: any, index: React.Key) => {
                                  return clas.schoolId.toString() === schools.toString() ? (
                                    <option
                                      key={index}
                                      className='text-[#9EA1B0]'
                                      value={clas.id}
                                    >
                                      {clas.name}
                                    </option>
                                  ) : null
                                }
                              )}
                            </select>
                          </div>
                        ) : null}
                      </div>
                    </form>

                    <div className='bg-white mt-11 flex justify-between gap-4'>
                      <button
                        id='cancel_user'
                        type='button'
                        className='w-auto inline-flex justify-center rounded px-4 py-2 bg-white text-sm font-bold mb-7 ml-8'
                        onClick={handleClose}
                      >
                        Cancelar
                      </button>
                      <button
                        id='link_user'
                        type='button'
                        className='w-auto inline-flex justify-center rounded px-4 py-2 bg-[#083CA6] text-sm font-bold text-white mb-7 mr-8'
                        onClick={handleLink}
                      >
                        Salvar permissões
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}
    </section>
  )
}
