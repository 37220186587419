import axios from "axios";
import { getLocalToken } from "../services/localStorage";

const httpScanner = axios.create({
  baseURL:
    "https://dev-api-login-integracao-dot-scanner-prova.rj.r.appspot.com",
  timeout: 10000,
});

const requestHandler = (request: any) => {
  const token = getLocalToken();
  if (token) request.headers.Authorization = `Bearer ${token}`;

  return request;
};

const responseHandler = (response: any) => {
  if (response.status === 401) {
    console.log(response);
  }

  return response;
};

const errorHandler = (error: any) => {
  return Promise.reject(error);
};

httpScanner.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

httpScanner.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default httpScanner;
