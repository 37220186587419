import { Grid, MenuItem, TextField, Button, Box } from "@mui/material";

import { useState } from "react";
import { IForm, ISchemaFormData } from "../../types";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { serializeStudent } from "../../utils/serializeStudent";

const MaintenanceStudentForm: React.FC<IForm> = ({
  data,
  onConfirm,
  onCancel,
  confirmButtonLabel,
}) => {
  const optionsYearSelect = [...Array(9).keys()].map((y) => `${y + 1}º`);
  const optionsGenreSelect = ["Feminino", "Masculino"];
  const [submit, setSubmit] = useState(false);

  const validationSchema = yup.object({
    year: yup.string().required("É necessário selecionar o ano da turma."),
    nameClass: yup.string().required("É necessário preencher a turma."),
    nameMother: yup.string().required("É necessário preencher o nome da mãe."),
    genre: yup.string().required("É necessário informar o sexo do aluno."),
    rg: yup.string().required("É necessário informar o RG do aluno."),
    birthDate: yup
      .string()
      .required("É necessário informar a data de nascimento do aluno."),
    teachingMode: yup
      .string()
      .required("É necessário informar o modo de ensino."),
    nameStudent: yup
      .string()
      .required("É necessário preencher o nome do(a) aluno(a)."),
    codRegistration: yup
      .string()
      .required("É necessário informar o código da matrícula do aluno."),
  });
  const formikParms = {
    initialValues: serializeStudent.toFormDate(data),
    validationSchema,
    onSubmit: (values: ISchemaFormData) => {
      onConfirm(serializeStudent.toDataStudent(values));
    },
  };
  return (
    <Formik {...formikParms}>
      {({ handleSubmit, errors, values }) => (
        <form
          style={{ width: "100%" }}
          onSubmit={(event) => {
            handleSubmit(event);
            setSubmit(true);
          }}
        >
          <Grid container columnSpacing={3} rowSpacing={4}>
            <Grid item xs={12}>
              <Field
                as={TextField}
                fullWidth
                id="nameStudent"
                name="nameStudent"
                label="Nome"
                variant="outlined"
                placeholder="Digite aqui o nome"
                helperText={submit && errors.nameStudent}
                error={submit && errors.nameStudent}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                id="codRegistration"
                name="codRegistration"
                label="Matrícula"
                helperText={submit && errors.codRegistration}
                error={submit && !!errors.codRegistration}
                variant="outlined"
                placeholder="Digite aqui a matrícula"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                InputLabelProps={{ shrink: true, required: true }}
                type="date"
                as={TextField}
                fullWidth
                id="birthDate"
                name="birthDate"
                label="Data de nascimento"
                variant="outlined"
                placeholder="Selecione a data de nascimento"
                helperText={submit && errors.birthDate}
                error={submit && errors.birthDate}
              />
            </Grid>

            <Grid item xs={4}>
              <Field
                as={TextField}
                select
                id="genre"
                name="genre"
                label="Sexo"
                helperText={submit && errors.genre}
                error={submit && !!errors.genre}
                variant="outlined"
                placeholder="Selecione o sexo"
                fullWidth
              >
                {optionsGenreSelect.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                as={TextField}
                fullWidth
                id="rg"
                name="rg"
                label="RG"
                variant="outlined"
                placeholder="Digite aqui o RG"
                helperText={submit && errors.rg}
                error={submit && errors.rg}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                as={TextField}
                fullWidth
                id="teachingMode"
                name="teachingMode"
                label="Modo de ensino"
                variant="outlined"
                placeholder="Selecione o modo de ensino"
                helperText={submit && errors.teachingMode}
                error={submit && errors.teachingMode}
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                as={TextField}
                fullWidth
                id="nameMother"
                name="nameMother"
                label="Nome da mãe"
                variant="outlined"
                placeholder="Digite aqui o nome da mãe"
                helperText={submit && errors.nameMother}
                error={submit && errors.nameMother}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                select
                id="year"
                name="year"
                label="Ano"
                helperText={submit && errors.year}
                error={submit && !!errors.year}
                variant="outlined"
                placeholder="Selecione o ano"
                fullWidth
              >
                {optionsYearSelect.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                fullWidth
                id="nameClass"
                name="nameClass"
                label="Turma"
                variant="outlined"
                placeholder="Digite aqui o nome da turma"
                helperText={submit && errors.nameClass}
                error={submit && errors.nameClass}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "40px",
            }}
          >
            <Button
              variant="text"
              style={{
                color: "black",
                textTransform: "none",
                fontWeight: "700",
              }}
              disableElevation
              type="reset"
              onClick={onCancel}
            >
              Cancelar
            </Button>

            <Button
              disableElevation
              style={{
                backgroundColor: "#083CA6",
                textTransform: "none",
                fontWeight: "700",
                color: "white",
              }}
              type="submit"
            >
              {confirmButtonLabel}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default MaintenanceStudentForm;
