import React, { 
  Fragment,  
  useEffect,  
  useState 
} from 'react';
import { createRoot } from 'react-dom/client';
import { Dialog, Transition } from '@headlessui/react';

import { Alert } from '../../../components/Alert';

import { addProfile } from '../../../api/profile';

import closeIcon from '../../../assets/icons/blackCloseIcon.svg';
import okIcon from '../../../assets/icons/ok.svg';
import { getSystemsData, getSystemsResources } from '../../../api/system';

interface systemProps {
  id: number
  name: string
}

export function AddProfile() {
  return new Promise((resolve, reject) => {
    addDialog(resolve);
  });
}

function removeDialog(root: any) {
  root.unmount();

  const div = document.getElementById('modal-add-profile');
  if (div) {
    div.remove();
  }
}

function addDialog(resolve: any) {
  const body = document.getElementsByTagName('body')[0];
  const div = document.createElement('div');

  div.setAttribute('id', 'modal-add-profile');
  body.appendChild(div);

  const root = createRoot(div);

  root.render(
    <CreateDialog 
      root={root} 
      resolve={resolve}
    />
  );
}

function CreateDialog (dialogObj: { root: any, resolve: any }) {
  const [isAddUserInfoOpen, setIsAddUserInfoOpen] = useState(true);
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);

  const [systems, setSystems] = useState([]);

  const [name, setName] = useState('');
  const [system, setSystem] = useState('');

  const handleAddUserInfo = () => {
    setIsAddUserInfoOpen(false);
    setIsAddUserOpen(true);
  }

  const handleBack = () => {
    setIsAddUserOpen(false);
    setIsAddUserInfoOpen(true);
  }

  const handleClose = () => {
    removeDialog(dialogObj.root);

    dialogObj.resolve({ changed: false });
  };

  const handleAdd = () => {
    removeDialog(dialogObj.root);

    addProfile({nivel: 1, name: name})
      .then((res: any) => {
        dialogObj.resolve({
          changed: true,
          data: res,
        });
      })
      .catch((err: any) => {
        Alert({
          type: 'error',
          message: 'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
          autoClose: true,
        })

        dialogObj.resolve([{ changed: false }]);
      });
  };

  useEffect(() => {
    getSystemsData().then((data) => {
      const newSystems = data.results.map((system: any) => {
        return {
          id: system.id_modulo,
          name: system.nome_modulo,
        }
      })

      setSystems(newSystems)
    })

    getSystemsResources().then((data) => {
    })
    
  }, []);

  return (
    <>
      {(isAddUserOpen || isAddUserInfoOpen) ? (
        <>
          <Transition.Root show={isAddUserInfoOpen || isAddUserOpen} as="div">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-[#14161F]  bg-opacity-40 transition-opacity" />
            </Transition.Child>
            
            <Dialog as="div" className="w-full z-10 relative" onClose={setIsAddUserInfoOpen}>
              <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end sm:items-center justify-center min-h-full text-center sm:p-0">
                  <Transition.Child
                    as="div"
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    className={`flex flex-1 justify-center items-center`}
                  >
                    {
                      isAddUserInfoOpen ? (
                        <Dialog.Panel className="relative w-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-screen-sm sm:w-full">
                          <div className="bg-white">
                            <div className="sm:flex sm:items-start">
                              <div className="text-center sm:text-left w-full">
                                <div className="flex flex-row justify-between mx-8 mt-8">
                                  <Dialog.Title as="h3" className="text-lg font-medium">
                                    Criar perfil de usuário
                                  </Dialog.Title>
                                  <img className="cursor-pointer" src={closeIcon} alt="Imagem de um x" onClick={handleClose} />
                                </div>
                                <div className="mt-10 mx-8 flex flex-row justify-between items-center text-profile">
                                  <p className="font-semibold">
                                    <span className="px-3 py-1 rounded-full bg-[#083CA6] text-white border-0 mr-2">
                                      1
                                    </span> Informações básicas
                                  </p>
                                  <span className="flex grow mx-4 h-px bg-[#BEC0CC] border-0"></span>
                                  <p className="text-[#9EA1B0]"><span className="px-3 py-1 rounded-full border border-[#9EA1B0] mr-2 font-semibold">2</span>Permissões</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col justify-start bg-white rounded-md">
                            <div className="flex flex-col mt-12 ml-8 mr-10">
                              <label className="text-[#5E6475] text-sm">Nome</label>
                              <input className="w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded placeholder:text-[#9EA1B0]" type="text" placeholder="Digite aqui o nome do perfil de usuário" onChange={(e) => setName(e.target.value)} value={name}/>
                            </div>
                            <div className="flex flex-col mt-2 ml-8 mr-10">
                              <label className="text-[#5E6475] text-sm">Sistema</label>
                              <select 
                                className="w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded" 
                                onChange={(e) => setSystem(e.target.value)} 
                                value={system}
                              >
                                <option value="DEFAULT" className="">Selecione o sistema</option>
                                {systems.map(
                                  (system: systemProps, index: React.Key) => {
                                    return (
                                      <option
                                        key={index}
                                        className=''
                                        value={system.id}
                                      >
                                        {system.name}
                                      </option>
                                    )
                                  }
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="bg-gray-50 mt-11 sm:flex justify-between">
                            <button
                              type="button"
                              className="w-auto inline-flex justify-center rounded px-4 py-2 bg-white text-sm font-bold mb-7 ml-8"
                              onClick={handleClose}
                            >
                              Cancelar
                            </button>
                            <button
                              type="button"
                              className="w-auto inline-flex justify-center rounded px-4 py-2 bg-[#083CA6] text-sm font-bold text-white mb-7 mr-8"
                              onClick={handleAddUserInfo}
                            >
                              Próximo
                            </button>
                          </div>
                        </Dialog.Panel>
                      ) : null
                    }

                    {
                      isAddUserOpen ? (
                        <Dialog.Panel className="relative w-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-screen-sm sm:w-full">
                          <div className="bg-white">
                            <div className="sm:flex sm:items-start">
                              <div className="text-center sm:text-left w-full">
                                <div className="flex flex-row justify-between mx-8 mt-8">
                                  <Dialog.Title as="h3" className="text-lg font-medium">
                                    Criar perfil de usuário
                                  </Dialog.Title>
                                  <img className="cursor-pointer" src={closeIcon} alt="Imagem de um x" onClick={handleClose} />
                                </div>
                                <div className="mt-10 mx-8 flex flex-row justify-between items-center text-profile text-[#252833]">
                                  <p className="flex flex-row justify-center items-center">
                                    <img src={okIcon} alt="Imagem de Ok" className=" px-2 py-2.5 rounded-full bg-[#F2F5FD] mr-2 border-0" /> Informações básicas
                                  </p>
                                  <span className="flex grow mx-4 h-px bg-[#BEC0CC] border-0"></span>
                                  <p className="text-[#252833] font-semibold ">
                                    <span className="px-3 py-1 rounded-full bg-[#083CA6] text-white border-0 mr-2">
                                      2
                                    </span>
                                    Permissões
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col justify-start bg-white rounded-md">
                            <div className="ml-8 mt-10">
                              <p className="text-sm">
                                Selecione as permissões que o perfil de usuário <span className="font-bold">{name}</span> terá no sistema <span className="font-bold">Comunidade de Práticas:</span>
                              </p>
                              
                              <div className="mt-5" >
                                <input type="checkbox" name="permission" id="1" />
                                <label className="ml-3 text-profile">Permissão 1</label>
                              </div>
                              <div className="mt-4" >
                                <input type="checkbox" name="permission2" id="2" />
                                <label className="ml-3 text-profile">Permissão 2</label>
                              </div>
                            </div>
                          </div>
                          <div className="bg-white mt-11 flex justify-between gap-4">
                            <button
                              type="button"
                              className="w-auto inline-flex justify-center rounded px-4 py-2 bg-white text-sm font-bold mb-7 ml-8"
                              onClick={handleBack}
                            >
                              Voltar
                            </button>
                            <button
                              type="button"
                              id="add_profile"
                              className="w-auto inline-flex justify-center rounded px-4 py-2 bg-[#083CA6] text-sm font-bold text-white mb-7 mr-8"
                              onClick={handleAdd}
                            >
                              Criar perfil de usuário
                            </button>
                          </div>
                        </Dialog.Panel>
                      ) : null
                    }
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      ) : null}
    </>
  )
}

