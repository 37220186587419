import { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import FormControlLabel from '@mui/material/FormControlLabel'
import BasicModal from '../../../../components/ModalManagement/BasicModal'
import {
  Button,
  TextField,
  IconButton,
  FormControl,
  Table,
  Box,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  TableContainer,
} from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import GroupIcon from '@mui/icons-material/Group'
import AddIcon from '@mui/icons-material/Add'

import { CustomPagination } from './CustomPagination'

import MaintenanceClassForm from '../MaintenanceClassForm'
import DeleteClassForm from '../DeleteClassForm'
import { IClasses } from '../../types'

export interface CardRowData {
  id: string
  name: string
  email: string
  accessSystems: Array<string>
  profiles: any
  inclusionDate: string
  status: boolean
  statusAction: (user: any) => Promise<any>
  editAction: (user: any) => Promise<any>
  deleteAction: (user: any) => Promise<any>
}

export interface CardRowDataProfile {
  id: string
  name: string
  accessSystems: Array<string>
  profiles: Array<string>
  inclusionDate: string
  status?: boolean
  statusAction?: (profile: any) => Promise<any>
  editAction: (profile: any) => Promise<any>
  deleteAction: (profile: any) => Promise<any>
}

export function TableManageClasses() {
  const rowsPerPage = 8
  const [filterList, setFilterList] = useState<string[]>([])

  const [data, setData] = useState()
  const [classes, setClasses] = useState<Array<IClasses>>([
    {
      id: uuid(),
      year: '9º',
      name: 'C',
      teacher: 'Maria Conceição da Silva',
      date: '13:36 29/07/2022',
      cpf: '089.089.089-89',
    },
  ])
  const [openModalMaintenance, setOpenModalMaintenance] = useState(false)
  const [actionTitle, setActionTitle] = useState<string>('Adicionar turma')
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [classInAction, setClassInAction] = useState<IClasses>({} as IClasses)
  const [page, setPage] = useState(1)
  const [pageFilterUser, setPageFilterUser] = useState('')
  const [pageFilterProfile, setPageFilterProfile] =
    useState('Todos os sistemas')

  const [pageCount, setPageCount] = useState(Math.ceil(8 / rowsPerPage))

  const handleSearch: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = ({ target }) => {}

  const handleClickAddClass = async () => {
    setOpenModalMaintenance(true)
    setActionTitle('Adicionar turma')
  }
  const handleClickEditClass = async (classData: IClasses) => {
    setClassInAction(classData)
    setActionTitle('Editar turma')
    setOpenModalMaintenance(true)
  }
  const handleSubmitMaintenanceForm = (data: IClasses) => {
    const foundIndex = classes.findIndex((c) => c.id === data.id)
    setClasses((current) => {
      let newClasses = [...current]
      if (foundIndex === -1) newClasses.push({ ...data, id: uuid() })
      else newClasses[foundIndex] = data
      console.log(foundIndex)

      return newClasses
    })
    setOpenModalMaintenance(false)
  }

  const handleClickDeleteClass = async (classData: IClasses) => {
    setClassInAction(classData)
    setOpenModalDelete(true)
    setActionTitle(
      `Tem certeza que deseja excluir a turma ${classData.year} ${classData.name}?`
    )
  }
  const handleDeleteClass = (classData: IClasses) => {
    const foundIndex = classes.findIndex((c) => c.id === classData.id)
    if (foundIndex === -1) return
    setClasses((current) => {
      let newClass = [...current]
      newClass.splice(foundIndex, 1)
      return newClass
    })
    setOpenModalDelete(false)
  }
  useEffect(() => {
    if (openModalMaintenance || openModalDelete) return
    setClassInAction({} as IClasses)
  }, [openModalMaintenance, openModalDelete])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '644px',
          padding: '24px',
          borderRadius: '4px',
          background: '#fff',
          paddingX: '16px',
          width: `calc(100vw - 4vw)`,
          '@media (min-width: 768px)': {
            width: 'auto',
            marginX: '5%',
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '32px',
              width: '100%',
              '@media (max-width: 768px)': {
                flexDirection: 'column',
                gap: '16px',
                marginBottom: '24px',
              },
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <GroupIcon
                style={{
                  color: '#083CA6',
                  background: '#F2F5FD',
                  width: '48px',
                  height: '48px',
                  padding: '12px',
                  borderRadius: '50%',
                  marginRight: '16px',
                }}
              />
              <Typography
                style={{
                  fontSize: '2rem',
                  color: '#083CA6',
                }}
              >
                Turmas
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                justifyContent: 'space-between',
                '@media (max-width: 768px)': {
                  width: '100%',
                  flexDirection: 'column',
                },
              }}
            >
              <FormControl
                sx={{
                  '@media (min-width: 768px)': { width: '350px' },
                  width: '100%',
                }}
                size='small'
              >
                <TextField
                  onChange={handleSearch}
                  label='Pesquise por ano, turma ou professor'
                  inputProps={{ style: { height: '7px' } }}
                  sx={{ '& label[data-shrink="false"]': { top: '-7px' } }}
                />
              </FormControl>

              <Button
                variant='contained'
                disableElevation
                startIcon={<AddIcon />}
                style={{
                  backgroundColor: '#083CA6',
                  textTransform: 'none',
                  fontWeight: '700',
                }}
                className=''
                onClick={() => handleClickAddClass()}
              >
                Adicionar turma
              </Button>
            </Box>
          </Box>
          <TableContainer className='mb-[24px]'>
            <Table
              sx={{
                tableLayout: 'fixed',
                border: '2px solid #F7F8FA',
                width: '1200px',
                '@media (min-width: 768px)': {
                  width: '100%',
                },
              }}
              padding='none'
            >
              <TableHead>
                <TableRow style={{ height: '48px', background: '#F7F8FA' }}>
                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='5%'
                    align='left'
                  >
                    Ano
                  </TableCell>
                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='5%'
                    align='left'
                  >
                    Turma
                  </TableCell>

                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='15%'
                    align='left'
                  >
                    Professor
                  </TableCell>

                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='20%'
                    align='left'
                  >
                    Data de inclusão
                  </TableCell>
                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='10%'
                    align='center'
                  >
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classes.map((item, index) => (
                  <TableRow>
                    <TableCell
                      sx={{ padding: '0 12px' }}
                      width={'16%'}
                      className='truncate ...'
                    >
                      {item.year}
                    </TableCell>

                    <TableCell
                      sx={{ padding: '0 12px' }}
                      className='truncate ...'
                    >
                      {item.name}
                    </TableCell>

                    <TableCell
                      sx={{ padding: '0 12px' }}
                      width='16%'
                      className='truncate ...'
                    >
                      {item.teacher}
                    </TableCell>
                    <TableCell
                      sx={{ padding: '0 12px' }}
                      className='truncate ...'
                    >
                      {item.date}
                    </TableCell>
                    <TableCell sx={{ padding: '0 12px' }} align='right'>
                      <FormControlLabel
                        control={
                          <IconButton
                            size='small'
                            aria-label='Editar'
                            onClick={() => handleClickEditClass(item)}
                            disableRipple
                          >
                            <EditIcon sx={{ m: 1 }} />
                          </IconButton>
                        }
                        label={''}
                      />
                      <FormControlLabel
                        control={
                          <IconButton
                            size='small'
                            aria-label='Excluir'
                            onClick={() => handleClickDeleteClass(item)}
                            disableRipple
                          >
                            <DeleteIcon color='error' sx={{ m: 1 }} />
                          </IconButton>
                        }
                        label={''}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display='flex' justifyContent='right'>
          <CustomPagination
            variant='outlined'
            shape='rounded'
            count={pageCount}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        </Box>
      </Box>
      <BasicModal
        title={actionTitle}
        open={openModalMaintenance}
        close={setOpenModalMaintenance}
      >
        <MaintenanceClassForm
          confirmButtonLabel={actionTitle}
          data={classInAction}
          onConfirm={handleSubmitMaintenanceForm}
          onCancel={() => setOpenModalMaintenance(false)}
        />
      </BasicModal>
      <BasicModal
        title={actionTitle}
        open={openModalDelete}
        close={setOpenModalDelete}
      >
        <DeleteClassForm
          data={classInAction}
          onConfirm={handleDeleteClass}
          onCancel={() => setOpenModalDelete(false)}
        />
      </BasicModal>
    </>
  )
}
