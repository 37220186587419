import styled from 'styled-components'
import { Dropdown } from '../Dropdown';

interface BreadProps {
  texto: string;
  nome: string;
}
export function Bread({texto, nome} : BreadProps){
  return (
    <div className='br-breadcrumb bg-blue-warm-vivid-70 d-flex '>
      <Margins>
        <div className='d-flex align-items-center flex-row text-pure-0'>
          <a href="/"><i className='fas fa-home'></i></a>
          {texto.length !== 0 ? <i className='fas fa-chevron-right'></i> : null}
          <ul className='crumb-list '>
            <li className='crumb m-0 text-weight-semi-bold '>
              {texto}
            </li>
            <div className="right-4 top-1 absolute d-flex md:-z-10 z-0 ">
              <button className='br-button circle hidden sm:block' type="button" ><i className="fas fa-bell"></i></button>
              <Dropdown username={nome} />
            </div>
          </ul>
        </div>
      </Margins>
  </div>
    
  );
};

const Margins = styled.div`
  @media (max-width: 768px) {
    margin-left:20px;
  }
  @media (min-width: 768px) {
    margin-left:30px;
  }
  @media (min-width: 1024px) {
    padding-left: 20px;
  }
  @media (min-width: 1366px) {
    margin-left: 90px;
  }
  @media (min-width: 1500px) {
    margin-left: 200px;
  }
`