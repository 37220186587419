import React, { Fragment, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { Dialog, Transition } from '@headlessui/react';

import { Alert } from '../../../components/Alert';

import { addStudent, editUser, getPermitedProfiles, getUserData } from '../../../api/user';
import { deleteUserProfile } from '../../../api/profile';

import closeIcon from '../../../assets/icons/blackCloseIcon.svg';
import closedEyeImg from '../../../assets/icons/closedEye.svg';
import lockIcon from '../../../assets/icons/lock.svg';
import negativeIcon from '../../../assets/icons/negative.svg';
import plusIcon from '../../../assets/icons/plus.svg';

import { LinkUser } from './LinkUser';

export function EditUser(userId: string, user: string, email: string, profiles: any) {
  return new Promise((resolve, reject) => {
    addDialog(userId, user, email, profiles, resolve);
  });
}

function removeDialog(root: any) {
  root.unmount();

  const div = document.getElementById('modal-edit-user');
  if (div) {
    div.remove();
  }
}

function addDialog(userId: string, user: string, email: string, profiles: any, resolve: any) {
  const body = document.getElementsByTagName('body')[0];
  const div = document.createElement('div');

  div.setAttribute('id', 'modal-edit-user');
  body.appendChild(div);

  const root = createRoot(div);

  root.render(
    <CreateDialog 
      root={root} 
      userId={userId} 
      user={user} 
      email={email}
      resolve={resolve}
      profiles={profiles}
    />
  );
}

function CreateDialog(dialogObj :{ root: any, userId: string, user: string, email: string, resolve : any, profiles: any}) {
  const [testData, setTestData] = useState<any>([]);
  const [wasDeleted, setWasDeleted] = useState(false);

  const [email, setEmail] = useState(dialogObj.email);
  const [tab, setTab] = useState(0);
  const [tabs] = useState([
    {
      id: 0,
      label: 'Informações gerais',
      tabMargin: 0,
      tabLength: 170,
    },
     {
      id: 1,
      label: 'Perfis de usuário',
      tabMargin: 32,
      tabLength: 150,
    }
  ]);

  const [toggleVisibilityNew, setToggleVisibilityNew] = useState(true);
  const [toggleVisibilityConfirmNew, setToggleVisibilityConfirmNew] = useState(true);

  const [visiblePassword, setVisiblePassword] = useState(false);

  const [inputNewPassword, setInputNewPassword] = useState('');
  const [inputConfirmPassword, setInputConfirmPassword] = useState('');

  const handleClose = () => {
    removeDialog(dialogObj.root);

    dialogObj.resolve();
  };

  const handleEdit = async () => {
    removeDialog(dialogObj.root);
    
    if(!inputNewPassword.length) {
      let allUserData: any = [];

      await getUserData(dialogObj.userId).then((data) => {
        allUserData = data;
      });

      dialogObj.resolve({
        changed: false,
        deleted: wasDeleted,
        data: allUserData
      })
    }
    editUser({
      cpf: dialogObj.userId,
      username: dialogObj.user,
      password: inputNewPassword
    })
    .then((res: any) => {
      dialogObj.resolve({
        changed: true,
        data: res,
        username: dialogObj.user,
        deleted: wasDeleted
      });
    })
    .catch((err: any) => {
      Alert({
        type: 'error',
        message: 'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
        autoClose: true,
      })
      
      dialogObj.resolve({ changed: false });
    });
  };

  const handleDelete = (profile: any, profileIndex: React.Key, schoolIndex: React.Key) => {
    deleteUserProfile(profile).then((result) => {
      if(result) {
        let newData = [...testData];
        newData[0].remover[profileIndex].escolas.splice(schoolIndex, 1);
        newData[0].remover[profileIndex].id_perfil_usuario.splice(schoolIndex, 1);
        setTestData(newData);
        setWasDeleted(true);
      }
    });
  }

  const handleLink = async () => {
    handleClose()
    const res: any = await LinkUser(
      dialogObj.user,
      dialogObj.userId
    )

    if(res.changed) {
      addStudent(res.data)
    }
  }

  const handleTabChange = (newValue: number) => {
    setTab(newValue);
  }

  const getWidth = (tab: number) => {
    return tabs.find((item: any) => item.id === tab)?.tabLength;
  }

  const calculateMargin = (tab: number) => {
    if (tab === 0) {
      return 0;
    } else {
      const index = tabs.findIndex((item: any) => item.id === tab);

      return tabs.slice(0, index).reduce((accumulator: number, currentValue: any) => {
        return accumulator + currentValue.tabLength + currentValue.tabMargin + 32;
      }, 0);
    }
  }

  useEffect(() => {
    getPermitedProfiles(dialogObj.userId).then((data: any) => {
      setTestData(data);
    })
  }, [dialogObj.userId]);

  return (
    <>      
      <Transition.Root show as="div" className="w-screen">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#14161F]  bg-opacity-40 transition-opacity" />
        </Transition.Child>

        <Dialog as="div" className="w-full z-10 relative" onClose={handleClose} id="basic">
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                leave="ease-in duration-200"
              >
                <Dialog.Panel className="relative w-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-screen-sm sm:w-full">
                  <div className="bg-white">
                    <div className="sm:flex sm:items-start">
                      <div className="text-center sm:text-left w-full">
                        <div className="flex flex-row justify-between mx-8 mt-8">
                          <Dialog.Title as="h3" className="text-lg font-medium">
                            Editar usuário
                          </Dialog.Title>
                          <img id="close_profile" className="cursor-pointer" src={closeIcon} alt="Imagem de um x" onClick={handleClose} />
                        </div>
                        <div className="mt-12 ml-8 flex flex-col content-start items-start">
                          <label className="text-user">Usuário</label>
                          <p className="mt-2 text-user">{dialogObj.user}</p>
                        </div>
                        <div className="flex ml-8 mt-6 border-t border-[#EDEEF2] pt-6 mr-8">
                          {tabs.map((currentTab, index) => (
                            <Button 
                              variant='text'
                              style={{
                                color: tab === currentTab.id ? '#083CA6' : '#404554',
                                fontSize: '1rem',
                                fontWeight: 600,
                                textTransform: 'none',
                                padding: '0px',
                                marginLeft: `${currentTab.tabMargin}px`,
                                width: `${currentTab.tabLength}px`,
                              }}
                              onClick={() => handleTabChange(currentTab.id)}
                              key={index}
                            >
                              {currentTab.label}
                            </Button>
                          ))}
                        </div>
                        <Box sx={{
                          height: '3px',
                          maxWidth: '90%',
                          borderRadius: '6px',
                          background: '#B6C4EE',
                          marginLeft: '32px'
                        }}>
                          <Box sx={{
                            height: '3px',
                            width: `${getWidth(tab)}px`,
                            borderRadius: '6px',
                            marginLeft: `${calculateMargin(tab)}px`,
                            transition: 'all 0.3s ease-in-out',
                            background: '#083CA6',
                          }}></Box>
                        </Box>
                      </div>
                    </div>
                  </div>
                  {tab === 0 && (
                    <>
                      <div className="flex flex-col justify-start bg-white rounded-md">
                        <div className="flex flex-col mt-12 ml-8 mr-10 opacity-70">
                          <label className="text-[#5E6475] text-sm">E-mail</label>
                          <input disabled className="w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded placeholder:text-[#9EA1B0]" type="text" placeholder="Digite aqui o nome do perfil de usuário" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="flex flex-col mt-4 ml-8 mr-10 pt-4 border-t border-[#EDEEF2]">
                          <label className="text-user">Senha</label>
                          { 
                            !visiblePassword ?
                              <div onClick={() => setVisiblePassword(!visiblePassword)} className="mt-4 flex flex-row justify-center items-center border border-[#DBDDE5] w-fit px-5 py-2 rounded cursor-pointer">
                                <img src={lockIcon} alt="Imagem de um cadeado" />
                                <p className="pl-3 font-bold text-sm text-[#083CA6]">Alterar senha</p>
                              </div> : null
                          }
                          {
                            visiblePassword ?
                            <>
                              <div className='flex flex-col mt-4'>
                                <label className='text-[#5E6475] text-sm'>Nova senha</label>
                                <div className='flex flex-row px-4 py-2 mt-2 bg-[#F7F8FA] focus-within:bg-white rounded'>
                                  <input 
                                    type={toggleVisibilityNew ? 'password' : 'text'}
                                    value={inputNewPassword}
                                    placeholder="Digite aqui a nova senha"
                                    onChange={(e) => setInputNewPassword(e.target.value)}
                                    className='w-full text-sm bg-[#F7F8FA] focus:bg-white outline-none' 
                                  />
                                  <span onClick={event => setToggleVisibilityNew(!toggleVisibilityNew)} className='w-6 h-4'>
                                    <img src={closedEyeImg} alt='Imagem de um olho fechado' />  
                                  </span>
                                </div>
                              </div>

                              <div className='flex flex-col mt-2'>
                                <label className='text-[#5E6475] text-sm'>Confirmar senha</label>
                                <div className='flex flex-row px-4 py-2 mt-2 bg-[#F7F8FA] focus-within:bg-white rounded'>
                                  <input 
                                    type={toggleVisibilityConfirmNew ? 'password' : 'text'}
                                    value={inputConfirmPassword}
                                    placeholder="Digite aqui novamente a nova senha"
                                    onChange={(e) => setInputConfirmPassword(e.target.value)}
                                    className='w-full text-sm bg-[#F7F8FA] focus:bg-white outline-none' 
                                  />
                                  <span onClick={event => setToggleVisibilityConfirmNew(!toggleVisibilityConfirmNew)} className='w-6 h-4'>
                                    <img src={closedEyeImg} alt='Imagem de um olho fechado' />  
                                  </span>
                                </div>
                              </div>
                            </> : null
                          }
                        </div>
                      </div>
                    </>
                  )}
                  {tab === 1 ? 
                    (<div className='flex flex-col'>
                      {testData.length === 1 ? testData[0].remover.map((profile: any, index: React.Key) => {
                        return(
                          profile.escolas.map((school: any, idx: React.Key) => {
                            return(
                              <div className="flex flex-col justify-start bg-white rounded-md" key={idx}>
                                <div className="ml-8 mt-12 flex flex-row items-end">
                                  <div className="flex flex-col w-1/3">
                                    <span className="text-sm text-[#BEC0CC]">Sistema</span>
                                    <select
                                      id='classes'
                                      className="mt-2 px-4 py-2 text-user bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]"
                                      defaultValue='DEFAULT'
                                      disabled
                                    >
                                      <option value="DEFAULT" className="text-sm">SAP</option>
                                    </select>
                                  </div>
    
                                  <div className="flex flex-col w-1/3 ml-6">
                                    <span className="text-sm text-[#5E6475]">Função de usuário</span>
                                    <select
                                      id='profile'
                                      className="mt-2 px-4 py-2 text-user bg-[#F7F8FA] rounded"
                                      defaultValue={profile.id_perfil}
                                    >
                                      <option value={profile.id_perfil} className="text-sm">{profile.perfil.descricao_perfil}</option>
                                    </select>
                                  </div>
    
                                  <button className="flex flex-row py-2.5 px-4 items-center absolute right-8" onClick={() => handleDelete(profile.id_perfil_usuario[idx], index, idx)}>
                                    <img src={negativeIcon} alt="Imagem de um menos vermelho" />
                                    <span className="ml-2.5 font-bold text-[#920000] text-sm">Excluir</span>
                                  </button>
                                </div>
                                {
                                  profile.perfil.acesso_escolas === false ?
                                  <div className="flex flex-col ml-8 mr-8">
                                    <div className='flex flex-row'>
                                      <div className="flex flex-col grow">
                                        <span className="text-sm text-[#5E6475]">Estado</span>
                                        <select
                                          id='profile'
                                          className="mt-2 px-4 py-2 text-user bg-[#F7F8FA] rounded"
                                          defaultValue={'state'}
                                        >
                                          <option value={'state'} className="text-sm">{school.estado}</option>
                                        </select>
                                      </div>
        
                                      <div className="flex flex-col ml-6 grow">
                                        <span className="text-sm text-[#5E6475]">Município</span>
                                        <select
                                          id='profile'
                                          className="mt-2 px-4 py-2 text-user bg-[#F7F8FA] rounded"
                                          defaultValue={'city'}
                                        >
                                          <option value={'city'} className="text-sm">{school.cidade}</option>
                                        </select>
                                      </div>
                                    </div>
                                    <span className="text-sm text-[#5E6475]">Escola</span>
                                    <select
                                      id='school'
                                      className='mt-2 px-4 py-2 text-user bg-[#F7F8FA] rounded'
                                      defaultValue={school.id_escola}
                                    >
                                      <option value={school.id_escola} className="text-sm">{school.nome_escola}</option>
                                    </select>
                                  </div> : null
                              }
                                
                              </div>
                            )   
                          })
                        )
                      }): null}
                      <div onClick={handleLink} className='mt-6 py-3 mx-8 border border-dashed border-[#7995DB] flex flex-row justify-center cursor-pointer'>
                        <img src={plusIcon} alt="Imagem de um mais" />
                        <p className='text-[#083CA6] font-bold text-sm ml-3'>Adicionar sistema</p>
                      </div>
                    </div>)
                   :  null}
                  <div className="bg-white mt-11 flex justify-between gap-4">
                    <button
                      type="button"
                      className="w-auto inline-flex justify-center rounded px-4 py-2 bg-white text-sm font-bold mb-7 ml-8 border"
                      onClick={handleClose}
                    >
                      Descartar alterações
                    </button>
                    <button
                      type="button"
                      className="w-auto inline-flex justify-center rounded px-4 py-2 bg-[#083CA6] text-sm font-bold text-white mb-7 mr-8"
                      onClick={() => handleEdit()}
                    >
                      Salvar alterações
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
} 