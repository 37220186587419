import { MainHeader } from '../../components/Header'
import { Bread } from '../../components/Bread/bread'
import TableManageSchools from './components/TableManageSchools'
import Box from '@mui/material/Box'

export default function ManageSchool() {
  return (
    <>
      <MainHeader
        username={'Redux?'}
        subtitles={['Gerenciamento de escolas']}
      />
      <Bread texto={'Gerenciamento de escolas'} nome={'Redux?'} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '24px 0 32px 0',
          background: '#f2f5fd',
          '@media (min-width: 768px)': {
            width: '100%',
          },
        }}
      >
        <Box sx={{ marginTop: '21px' }}>
          <TableManageSchools
            profile={false}
            buttonAction={async () => null}
            data={[]}
          />
        </Box>
      </Box>
    </>
  )
}
