interface InvalidInputProps {
  text: string;
  hidden: boolean;
}

export function InvalidInput({text, hidden} : InvalidInputProps) {
  return(
    <>
    {
      hidden ?
        <div className='flex flex-row justify-center items-center bg-[#E52207] w-fit text-sm text-[#FCFCFC] px-1 mt-2'>
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" className='h-4 w-4'>
            <path d="M10 0.8125C4.64844 0.8125 0.3125 5.14844 0.3125 10.5C0.3125 15.8516 4.64844 20.1875 10 20.1875C15.3516 20.1875 19.6875 15.8516 19.6875 10.5C19.6875 5.14844 15.3516 0.8125 10 0.8125ZM14.7266 13.0781C14.9219 13.2344 14.9219 13.5469 14.7266 13.7422L13.2031 15.2656C13.0078 15.4609 12.6953 15.4609 12.5391 15.2656L10 12.6875L7.42188 15.2656C7.26562 15.4609 6.95312 15.4609 6.75781 15.2656L5.23438 13.7031C5.03906 13.5469 5.03906 13.2344 5.23438 13.0391L7.8125 10.5L5.23438 7.96094C5.03906 7.80469 5.03906 7.49219 5.23438 7.29688L6.79688 5.77344C6.95312 5.57812 7.26562 5.57812 7.46094 5.77344L10 8.3125L12.5391 5.77344C12.6953 5.57812 13.0078 5.57812 13.2031 5.77344L14.7266 7.29688C14.9219 7.49219 14.9219 7.80469 14.7266 7.96094L12.1875 10.5L14.7266 13.0781Z" fill="white"/>
          </svg>
          <p className='ml-1'>{text}</p>
        </div> : null
    }
    </>
  );
};