import axios from 'axios'

import {
  getLocalToken,
  setLocalToken,
  setLocalCPF,
} from '../services/localStorage'

const api = axios.create({
  baseURL: 'https://scanner-prova.rj.r.appspot.com/',
})

export const userLogin = async (userCPF: string, password: string) => {
  const cleanCPF = userCPF.replace(/[^\d]/g, '')

  return await api
    .post('govbr/user/token/', {
      cpf: cleanCPF,
      password,
    })
    .then((res) => {
      setLocalToken(res.data.refresh)

      const success = res.status === 200
      if (success) {
        setLocalCPF(cleanCPF)
      }

      return success
    })
    .catch(() => {
      return false
    })
}

export const userRegister = async (info: any) => {
  const token = getLocalToken()
  const cleanCPF = info.cpf.replace(/[^\d]/g, '')

  const res = await api.post('govbr/user/', {
      cpf: cleanCPF,
      email: info.email,
      nome_usuario: info.username,
      password: info.password,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return res
}

export const getUsersData = async () => {
  const token = getLocalToken()
  const res = await api.get('govbr/user/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return res.data
}

export const getUsersDataTabbed = async (limit:number, offset:number) => {
  const token = getLocalToken()
  const res = await api.get(`govbr/user/?limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return res.data
}

export const getUserData = async (userCPF: string) => {
  const token = getLocalToken()
  const res = await api.get(`govbr/user/${userCPF}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return res.data
}

export const editUser = async (info: any) => {
  const token = getLocalToken()
  const res = await api.patch(`govbr/user/${info.cpf}/`, {
      cpf: info.cpf,
      email: info.email,
      celular: info.phoneNumber,
      password: info.password,
      nome_usuario: info.username,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return res.data
}

export const deleteUser = async (userCPF: string) => {
  const token = getLocalToken()
  return await api
    .delete(`govbr/user/${userCPF}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.status === 204
    })
    .catch(() => {
      return false
    })
}

export const editPassword = async (userCPF: string, password: any) => {
  const token = getLocalToken()
  return await api
    .put(`/users/${userCPF}/`, {
        password: password.old,
        newPassword: password.new,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.status === 200
    })
    .catch(() => {
      return false
    })
}

export const getCustom = async (userCPF: string) => {
  const token = getLocalToken()
  const res = await api.get(`custom/perfis/${userCPF}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return res.data
}

export const getPermitedProfiles = async (userCPF: string) => {
  const token = getLocalToken();
  const res = await api.get(`custom/perfis/${userCPF}?remover=True`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })

  return res.data
}

export const addStudent = async (info: any) => {
  const token = getLocalToken()
  const res = await api.post('aluno/', {
      nome_turma: info.className,
      nome_aluno: info.name,
      id_turma: info.classId,
      cpf: info.id,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return res.status
}

export const deactivateAccount = (userCPF: string, active: boolean) => {
  const token = getLocalToken()
  const res = api.patch(`/govbr/user/${userCPF}/`, {
      active,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return res
}

export const getLoggedUserData = async () => {
  const token = getLocalToken();
  const res = await api.get('/govbr/user/info/1/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
}