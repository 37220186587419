import React from "react";
import styled from "styled-components";

export default function TopCardScholls() {
  const Button1Function = (e: any, name: string) => {
    alert(`${name} was clicked`);
  };
  return (
    <FrameRoot>
      <FlexRow>
        <Button1 onClick={(e) => Button1Function(e, "Button1")}>
          <ButtonBase>
            <Arrowleft
              src={"https://file.rendit.io/n/ypvrNQ9X9TjTML4xtgS6.svg"}
            />
          </ButtonBase>
        </Button1>
        <FlexColumn className="space-y-[30px]">
          <FlexRow1 alignItems={"center"}>
            <FlexColumn1 width={"550px"}>
              <Text1>Escola</Text1>
              <Text2>EEEFM MARIO OLIVEIRA CHAVES</Text2>
            </FlexColumn1>
            <FlexRow2 alignItems={"center"}>
              <FlexColumn1 width={"350px"}>
                <Text1>Gestor escolar</Text1>
                <Text4>Maria Conceição da Silva</Text4>
              </FlexColumn1>
              <Button1 onClick={(e) => Button1Function(e, "Button2")}>
                <ButtonBase1>
                  <Arrowleft
                    src={"https://file.rendit.io/n/NsBl866ZZ626jS4u3pvm.svg"}
                  />
                  <ButtonLabel>Editar escola</ButtonLabel>
                </ButtonBase1>
              </Button1>
            </FlexRow2>
          </FlexRow1>

          <FlexRow1 alignItems={"center"}>
            <FlexColumn1 width={"550px"}>
              <Text1>Endereço</Text1>
              <Paragraph alignSelf={"stretch"}>
                R. Ananias Honório de Souza, 45 - CENTRO, 58520-000
              </Paragraph>
            </FlexColumn1>
            <FlexRow2 alignItems={"flex-start"}>
              <FlexColumn1 width={"350px"}>
                <Text1>Gestor escolar</Text1>
                <Text4>Maria Conceição da Silva</Text4>
              </FlexColumn1>
              <FlexColumn5>
                <Text1>Estado</Text1>
                <Paragraph alignSelf={"inherit"}>PB</Paragraph>
              </FlexColumn5>
            </FlexRow2>
          </FlexRow1>
        </FlexColumn>
      </FlexRow>
    </FrameRoot>
  );
}
const FrameRoot = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  margin: auto;
  width: calc(100vw - 4vw);
  padding: 24px;
  border-radius: 4px;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    width: auto;
    margin: 0 5% 32px 5%;
  }
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  width: 100%;
`;
const ButtonBase = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 8px;
`;
const Text2 = styled.div`
  font-size: 20px;
  font-family: Inter;
  font-weight: 500;
  line-height: 30px;
`;
const Text4 = styled.div`
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  line-height: 23.8px;
`;
const ButtonBase1 = styled.div`
  border-width: 1px;
  border-color: #dbdce5;
  border-style: solid;
  background-color: #ffffff;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  flex-grow: 1;
  border-radius: 4px;
  padding: 8px 16px;
  gap: 8px;
`;
const ButtonLabel = styled.div`
  font-size: 14px;
  font-family: Inter;
  font-weight: 700;
  line-height: 14px;
  color: #073ca5;
  display: inline-block;
`;
const FlexColumn5 = styled.div`
  width: 143px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Button1 = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  border-width: 0px;
  background: none;
  box-sizing: content-box;
  cursor: pointer;
  &: hover {
    opacity: 70%;
  }
`;
const Arrowleft = styled.img`
  width: 24px;
  height: 24px;
`;
const FlexColumn = styled.div`
  display: "flex";
  flex-direction: "column";
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
`;
type FlexR = {
  alignItems: string;
};
const FlexRow1 = styled.div<FlexR>`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-start;
  align-self: stretch;
  width: 100%;
  align-items: ${(props) => props.alignItems};
`;
type FlexCol1 = {
  width: string;
};
const FlexColumn1 = styled.div<FlexCol1>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  width: ${(props) => props.width};
`;
const Text1 = styled.div`
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  line-height: 15.96px;
`;

const FlexRow2 = styled.div<FlexR>`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-start;
  flex-grow: 1;
  align-items: ${(props) => props.alignItems};
`;
type ParagraphProp = {
  alignSelf: string;
};
const Paragraph = styled.div<ParagraphProp>`
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 24px;
  align-self: ${(props) => props.alignSelf};
`;
