import { useEffect, useState } from 'react';

import { Alert } from '../Alert';

import { getLocalCPF } from '../../services/localStorage';

import { editUser } from '../../api/user';
import { refreshToken } from '../../api/token';

import blackCloseImg from '../../assets/icons/blackCloseIcon.svg';
import success from '../../assets/icons/success.svg';
import warning from '../../assets/icons/warning.svg';

interface ContactInfoProps {
  setHidden: () => void;
  hidden: boolean;
}

export function EditContactInfo({ setHidden, hidden }: ContactInfoProps) {
  const cpf = getLocalCPF();

  const [inputDDD, setInputDDD] = useState('');
  const [inputPhone, setInputPhone] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [inputWarName, setInputWarName] = useState('');

  const [isHidden , setIsHidden] = useState(false);
  const [isErrorHidden, setIsErrorHidden] = useState(false);
  const [isSuccessHidden, setIsSuccessHidden] = useState(false);

  const checkNull = () => {
    if (
      inputDDD === '' ||
      inputPhone === '' ||
      inputEmail === '' ||
      inputWarName === ''
    ) {
      return true;
    }
    return false;
  };

  const setToNull = () => {
    setInputDDD('');
    setInputPhone('');
    setInputEmail('');
    setInputWarName('');
  };

  const handleCloseContactInfo = () => {
    setIsHidden(!isHidden);
    setToNull();
    setHidden();
  };

  const handleSaveChanges = async () => {
    if(checkNull()) {
      setIsErrorHidden(true);
      handleCloseContactInfo();
      console.log('Preencha todos os campos');
      return;
    }

    const refreshResponse = await refreshToken();
    console.log(refreshResponse);

    const editReponse = await editUser({
      cpf,
      email: inputEmail,
      phoneDDD: inputDDD,
      phoneNumber: inputPhone,
      warName: inputWarName
    });

    if (editReponse) {
      setIsSuccessHidden(true);
    } else {
      Alert({
        type: 'error',
        message: 'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
        autoClose: true,
      });
    }

    handleCloseContactInfo();
  }

  const handleDDDChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let ddd = e.target.value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1');
    setInputDDD(ddd);
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let phone = e.target.value.replace(/\D/g, '').replace(/(\d{5})(\d{4})(\d)/, '$1-$2');
    setInputPhone(phone);
  }

  useEffect(() => {
    setIsHidden(hidden);
  }, [hidden]);

  return (
    <>
      {
        isSuccessHidden ?
          <div className='w-full mt-16 flex justify-center z-50'>
            <div className='w-2/5 h-fit flex flex-row items-center py-4 bg-[#F4FDF4] rounded border-[#B2EDB0] border-x border-y'>
              <img src={success} alt='Imagem de um Ok' className='ml-4' />
              <span className='w-4/5 ml-3 text-[#196416] text-profile font-medium'>Informações de contato atualizadas com sucesso.</span>
              <img src={blackCloseImg} alt='Imagem de um x' className='h-3 w-3 ml-8 mr-6 cursor-pointer' onClick={() => setIsSuccessHidden(!isSuccessHidden)} />
            </div>
          </div>
        : null
      }
      {
        isErrorHidden ? 
          <div className='w-full mt-16 flex justify-center z-50'>
            <div className='w-2/5 h-fit flex flex-row items-center py-4 bg-[#F3D8DB] rounded border-[#EDC4C8] border-x border-y'>
              <img src={warning} alt='Imagem de um aviso' className='ml-4' />
              <span className='w-4/5 ml-3 text-[#920000] text-profile font-medium'>Ops! Ocorreu um erro ao salvar as alterações. Tente novamente mais tarde.</span>
              <img src={blackCloseImg} alt='Imagem de um x' className='h-3 w-3 ml-3 mr-6 cursor-pointer' onClick={() => setIsErrorHidden(!isErrorHidden)} />
            </div>
          </div>
        : null
      }
      {
        isHidden ?
          <section className='w-full h-full z-50 fixed flex items-center justify-center bg-[#14161f66]'>
            <div className='md:w-2/5 w-full md:h-auto flex flex-col justify-start bg-white rounded-md'>
              <div className='w-full flex flex-row justify-start relative'>
                <h2 className='w-4/5 text-xl font-medium mx-8 mt-8'>Editar informações de contato</h2>
                <img src={blackCloseImg} alt='Imagem de um x' className='mt-10 mr-10 w-4 h-4 cursor-pointer' onClick={handleCloseContactInfo} />
              </div>
              <div className='flex flex-col mt-12 ml-8 mr-10'>
                <label className='text-[#5E6475] text-sm'>DDD do celular</label>
                <input className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded' type='text' value={inputDDD} onChange={(e) => handleDDDChange(e)} />
              </div>
              <div className='flex flex-col mt-2 ml-8 mr-10'>
                <label className='text-[#5E6475] text-sm'>Celular</label>
                <input className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded' type='text' value={inputPhone} onChange={(e) => handlePhoneChange(e)} />
              </div>
              <div className='flex flex-col mt-2 ml-8 mr-10'>
                <label className='text-[#5E6475] text-sm'>E-mail</label>
                <input className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded' type='email' value={inputEmail} onChange={(e) => setInputEmail(e.target.value)} />
              </div>
              <div className='flex flex-col mt-2 ml-8 mr-10'>
                <label className='text-[#5E6475] text-sm'>Nome de guerra</label>
                <input className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded' type='text' value={inputWarName} onChange={(e) => setInputWarName(e.target.value)} />
              </div>
              <div className='relative'>
                <button onClick={handleCloseContactInfo}  className='py-2 px-4 ml-8 mt-11 mb-7 rounded'>
                  <span className='text-[#404554] font-bold text-sm'>
                    Descartar alterações
                  </span>
                </button>
                <button onClick={handleSaveChanges} className='absolute bottom-7 right-8 py-2 px-4 bg-[#083CA6] rounded'>
                  <span className='text-white font-bold text-sm'>
                      Salvar
                    </span>
                </button>
              </div>
            </div>
          </section>
          
          : null
      }
    </>
  );
};