import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'

import StepperComponent from '../../../../components/Stepper'
import BasicModal from '../../../../components/ModalManagement/BasicModal'
import styled from 'styled-components'

import InputMask from 'react-input-mask'

type Props = {
  open: boolean
  close: (value: boolean) => void
}

export default function AddSchool(props: Props) {
  const [openCreate, setOpenCreate] = useState(false)

  useEffect(() => {
    setOpenCreate(props.open)
  }, [props.open])

  useEffect(() => {
    props.close(openCreate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!openCreate])

  const handleSubmit = async () => {
    // do something
    console.log('done')
  }

  return (
    <BasicModal
      title='Adicionar escola'
      open={openCreate}
      close={setOpenCreate}
      onSubmit={handleSubmit}
    >
      <StepperComponent
        steps={['Dados da escola', 'Vincular Gestor', 'Vincular programas']}
        finishLabel='Adicionar escola'
        nextLabel='Próximo'
        cancelLabel='Cancelar'
        close={setOpenCreate}
        onSubmit={handleSubmit}
      >
        {/* STEP 1*/}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '0px',
            gap: '16px',
            width: '600px',
            height: '248px',
            flex: 'none',
            order: 1,
            flexGrow: 0,
          }}
        >
          <Group gap='0' flexDirection='column'>
            <Label color='#5e6475'>Nome</Label>
            <Input
              id='name'
              type='text'
              name='name'
              placeholder='Digite aqui o nome da escola'
            />
          </Group>
          <Group gap='24px' flexDirection='row'>
            <SubGroup>
              <Label color='#5e6475'>Estado</Label>
              <InputSelect>
                <option value=''>Selecione o estado</option>
              </InputSelect>
            </SubGroup>

            <SubGroup>
              <Label color='#5e6475'>Município</Label>
              <InputSelect>
                <option value=''>Selecione o município</option>
              </InputSelect>
            </SubGroup>
          </Group>
          <Group gap='0' flexDirection='column'>
            <Label color='#5e6475'>Endereço</Label>
            <Input
              id='address'
              type='text'
              name='address'
              placeholder='Digite aqui o endereço'
            />
          </Group>
        </Box>

        {/* STEP 2 */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '16px  0',
            gap: '32px',
            width: '600px',
            height: '248px',
            flex: 'none',
            order: 1,
            flexGrow: 0,
          }}
        >
          <Group gap='0' flexDirection='column'>
            <Label fontWeight={700}>Escola</Label>
            <Text>EEEFM MARIO OLIVEIRA CHAVES</Text>
          </Group>

          <Group gap='0' flexDirection='column'>
            <Text>Vincule o gestor responsável pela escola</Text>
          </Group>

          <Group gap='0' flexDirection='column'>
            <Label>CPF</Label>
            <Input
              id='cpf'
              type='text'
              name='cpf'
              mask='999.999.999-99'
              placeholder='Digite aqui o CPF do gestor'
            />
          </Group>
        </Box>
        <div>chil 3</div>
      </StepperComponent>
    </BasicModal>
  )
}

const InputText = styled.input`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 10px;

  width: 600px;
  height: 40px;

  background: #f7f8fa;
  border-radius: 4px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`
type label = {
  color?: string
  fontWeight?: number
}

const Label = styled.label<label>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  font-size: 14px;

  color: ${(props) => (props.color ? props.color : 'black')};

  flex: none;
  order: 0;
`
type GroupDirection = {
  flexDirection: string
  gap: string
}
const Group = styled.div<GroupDirection>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  align-items: flex-center;
  padding: 0px;
  gap: ${(props) => props.gap};
  margin-top: 0px;

  width: 100%;
  max-height: 72px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`
const SubGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 288px;
  height: 72px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
`

const InputSelect = styled.select`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #9ea1b0;

  width: 100%;
  height: 40px;

  /* Primitive/50 */

  background: #f7f8fa;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`
const Text = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;

  color: #000000;

  flex: none;
  order: 1;
  flex-grow: 0;
`

type input = {
  id?: string
  placeholder?: string
  name?: string
  mask?: string
  type?: string
  value?: string
}

const Input = (props: input) => (
  <InputMask mask={props.mask ? props.mask : ''} value={props?.value}>
    {() => (
      <InputText
        type='text'
        name={props.name}
        placeholder={props.placeholder}
      />
    )}
  </InputMask>
)
