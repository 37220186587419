import { useState, useEffect } from 'react'

import FormControlLabel from '@mui/material/FormControlLabel'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ApartmentIcon from '@mui/icons-material/Apartment'
import AddIcon from '@mui/icons-material/Add'
import IosShareIcon from '@mui/icons-material/IosShare'

import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'

import { CustomPagination } from './CustomPagination'
import { ButtonBase, TablePagination } from '@mui/material'
import BasicModal from '../../../../components/ModalManagement/BasicModal'
import AddSchool from '../AddSchoolForm'

export default function TableManageSchools(props: any) {
  const rowsPerPage = props.rowsPerPage || 8
  const [filterList, setFilterList] = useState<string[]>([])

  const [data, setData] = useState(props.data)
  const [currentData, setCurrentData] = useState(data)
  const [rowData, setRowData] = useState(data.slice(0, rowsPerPage))

  const [page, setPage] = useState(1)
  const [pageFilterUser, setPageFilterUser] = useState('')
  const [pageFilterProfile, setPageFilterProfile] =
    useState('Todos os sistemas')

  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)

  const [pageCount, setPageCount] = useState(
    Math.ceil(data.length / rowsPerPage)
  )

  const [openAdd, setOpenAdd] = useState(false)

  /* Props Use Effect */
  useEffect(() => {
    setData(props.data)
    setCurrentData(props.data)
  }, [props.data])

  useEffect(() => {
    const newFilterList = new Set<string>()

    data.forEach((row: any) => {
      row.accessSystems.forEach((system: any) => {
        newFilterList.add(system)
      })
    })

    setFilterList(Array.from(newFilterList))
  }, [data])

  const handleSubmit = async () => {
    // do something
    console.log('done')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '644px',
        padding: '24px',
        borderRadius: '4px',
        background: '#fff',
        paddingX: '16px',
        width: `calc(100vw - 4vw)`,
        '@media (min-width: 768px)': {
          width: 'auto',
          marginX: '5%',
        },
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '32px',
            width: '100%',
            '@media (max-width: 768px)': {
              flexDirection: 'column',
              gap: '16px',
              marginBottom: '24px',
            },
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <ApartmentIcon
              style={{
                color: '#083CA6',
                background: '#F2F5FD',
                width: '48px',
                height: '48px',
                padding: '12px',
                borderRadius: '50%',
                marginRight: '16px',
              }}
            />
            <Typography
              style={{
                fontSize: '2rem',
                color: '#083CA6',
              }}
            >
              Escolas
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              justifyContent: 'space-between',
              '@media (max-width: 768px)': {
                width: '100%',
                flexDirection: 'column',
              },
            }}
          >
            <FormControl
              sx={{
                '@media (min-width: 768px)': { width: '350px' },
                width: '100%',
              }}
              size='small'
            >
              <TextField
                value={pageFilterUser}
                onChange={(event) => setPageFilterUser(event.target.value)}
                label='Pesquise usuários por nome ou CPF'
                inputProps={{ style: { height: '7px' } }}
                sx={{ '& label[data-shrink="false"]': { top: '-7px' } }}
              />
            </FormControl>
            <Button
              variant='contained'
              disableElevation
              startIcon={<AddIcon />}
              style={{
                backgroundColor: '#083CA6',
                textTransform: 'none',
                fontWeight: '700',
              }}
              onClick={() => setOpenAdd(true)}
            >
              Adicionar escola
            </Button>
          </Box>
        </Box>
        <TableContainer className='mb-[24px]'>
          <Table
            sx={{
              tableLayout: 'fixed',
              border: '2px solid #F7F8FA',
              width: '1200px',
              '@media (min-width: 768px)': {
                width: '100%',
              },
            }}
            padding='none'
          >
            <TableHead>
              <TableRow style={{ height: '48px', background: '#F7F8FA' }}>
                <TableCell
                  sx={{ padding: '0 12px', fontWeight: 600 }}
                  width='35%'
                  align='left'
                >
                  Nome e Endereço
                </TableCell>
                <TableCell
                  sx={{ padding: '0 12px', fontWeight: 600 }}
                  width='10%'
                  align='left'
                >
                  Cidade
                </TableCell>

                <TableCell
                  sx={{ padding: '0 12px', fontWeight: 600 }}
                  width='5%'
                  align='left'
                >
                  Estado
                </TableCell>
                <TableCell
                  sx={{ padding: '0 12px', fontWeight: 600 }}
                  width='16%'
                  align='left'
                >
                  Gestor escolar
                </TableCell>
                <TableCell
                  sx={{ padding: '0 12px', fontWeight: 600 }}
                  width='10%'
                  align='left'
                >
                  Data de inclusão
                </TableCell>
                <TableCell
                  sx={{ padding: '0 12px', fontWeight: 600 }}
                  width='10%'
                  align='center'
                >
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: '0 12px' }} width='16%'>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      padding: '0px',
                      gap: '4px',
                      width: '100%',
                      height: '44px',
                      flex: 'none',
                      order: 0,
                      flexGrow: 0,
                    }}
                    className='truncate ...'
                  >
                    <div
                      style={{
                        textTransform: 'uppercase',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '170%',
                        color: '#000000',
                      }}
                    >
                      Escola estadual teste teste teste testes testes teste
                      steste
                    </div>
                    <div
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '133%',

                        color: '#404554',
                      }}
                    >
                      R. Ananias Honório de Souza, 45 - CENTRO, 58520-000 Santa
                      Lucia Tabuleiro
                    </div>
                  </div>
                </TableCell>

                <TableCell sx={{ padding: '0 12px' }} className='truncate ...'>
                  TEste
                </TableCell>

                <TableCell
                  sx={{ padding: '0 12px' }}
                  width='16%'
                  className='truncate ...'
                >
                  Teste
                </TableCell>
                <TableCell sx={{ padding: '0 12px' }} className='truncate ...'>
                  Teste
                </TableCell>

                <TableCell sx={{ padding: '0 12px' }} width='16%'>
                  Teste
                </TableCell>
                <TableCell sx={{ padding: '0 12px' }} align='right'>
                  <FormControlLabel
                    control={
                      <IconButton
                        size='small'
                        aria-label='Editar'
                        onClick={() => setOpenEdit(true)}
                        disableRipple
                      >
                        <EditIcon sx={{ m: 1 }} />
                      </IconButton>
                    }
                    label={''}
                  />
                  <FormControlLabel
                    control={
                      <IconButton
                        size='small'
                        aria-label='Excluir'
                        onClick={() => setOpenDelete(true)}
                        disableRipple
                      >
                        <DeleteIcon color='error' sx={{ m: 1 }} />
                      </IconButton>
                    }
                    label={''}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0px',
          gap: '24px',

          width: '100%',
          height: '40px',

          /* Inside auto layout */

          flex: 'none',
          order: 1,
          alignSelf: 'stretch',
          flexGrow: 0,
        }}
        className='justify-between'
      >
        <ButtonBase
          sx={{
            boxSizing: 'border-box',

            /* Auto layout */

            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '8px 16px',
            gap: '8px',

            width: '156px',
            height: '40px',

            /* Primitive/White */

            background: '#FFFFFF',
            /* Primitive/200 */

            border: '1px solid #DBDDE5',
            borderRadius: '4px',

            /* Inside auto layout */

            flex: 'none',
            order: 0,
            alignSelf: 'stretch',
            flexRow: 0,
          }}
        >
          <IosShareIcon style={{ color: '#083CA6' }} />
          <div
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '100%',
              color: '#083CA6',
              flex: 'none',
              order: 1,
              flexGrow: 0,
            }}
          >
            Exportar lista
          </div>
        </ButtonBase>

        <CustomPagination
          variant='outlined'
          shape='rounded'
          count={pageCount}
          page={page}
          onChange={(e, value) => setPage(value)}
        />
      </Box>

      {/* CREATE MODAL */}
      <AddSchool open={openAdd} close={setOpenAdd} />

      {/* EDIT MODAL */}
      <BasicModal
        title='Editar escola'
        open={openEdit}
        close={setOpenEdit}
        onSubmit={handleSubmit}
        buttons={true}
      >
        A
      </BasicModal>
      {/* DELETE MODAL */}
      <BasicModal
        title='Tem certeza que deseja excluir a escola <ESCOLA>?'
        open={openDelete}
        close={setOpenDelete}
        onSubmit={handleSubmit}
        buttons={true}
        confirmBtn='Excluir escola'
        confirmBtnColor='error'
      >
        B
      </BasicModal>
    </Box>
  )
}
