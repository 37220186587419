import { Dropdown } from './Dropdown'
import logo from '../assets/logo.svg';

interface MainHeaderProps {
  username: string;
  subtitles: string[];
}
export function MainHeader({ username, subtitles } : MainHeaderProps) {
  
  return (
    <header className='br-header compact hidden tablet:flex'>
      <div className='container-lg d-flex flex-column '>
        <div className='header-top align-items-center'>
            <div className='d-flex justify-content-between'>
              <div className="left d-flex ">
                <img style={styles.imgHeader} src={logo} alt="plataforma-logo" />
                <div className='header-actions br-list d-flex flex-row m-0'>
                  <div className='br-list-item p-0'>
                    <a className='br-item rounder-lg text-weight-bold m-0 ' href='/'>
                      Ajuda
                    </a>
                  </div>
                  <div className='br-list-item p-0'>
                    <a className='br-item rounder-lg text-weight-bold m-0' href='#'>
                      Sobre
                    </a>
                  </div>
                  <div className='br-list-item p-0'>
                    <a className='br-item rounder-lg text-weight-bold m-0' href='#'>
                      Contato
                    </a>
                  </div>
                  
                </div>
              </div>
              <div className="right d-flex">
                <button className='br-button circle' type="button" ><i className="fas fa-bell"></i></button>
                <Dropdown username={username} />
              </div>
            </div>
            
        </div>
        <div className='header-bottom py-2x d-flex align-items-center justify-content-start'>
          <button className="br-button circle small">
            <i className="fas fa-bars"></i>
          </button>
          <div className="text ml-2x">
            <div className="title text-up-02 text-weight-semi-bold py-2">Plataforma de Recuperação da Aprendizagem e Redução do Abandono</div>
            <div className="subtitle text-down-04">{subtitles}</div>
          </div>
        </div>
      </div>
    </header>
  )
}
const styles = {
  imgHeader: {
    maxHeight: '2.75rem',
    width: '9rem',
  }
  
}
