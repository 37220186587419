const refreshToken = 'refreshToken'
const accessToken = 'accessToken'
const cpfKey = 'CPF_KEY'
const code = 'code'
export const getLocalToken = () => {
  return localStorage.getItem(accessToken) || ''
}

export const setLocalToken = (token: string) => {
  localStorage.setItem(accessToken, token)
}

export const setLocalRefreshToken = (token: string) => {
  localStorage.setItem(refreshToken, token);
}

export const removeLocalToken = () => {
  localStorage.removeItem(accessToken)
  localStorage.removeItem(refreshToken)
}

export const getLocalCPF = () => {
  return localStorage.getItem(cpfKey) || ''
}

export const setLocalCPF = (cpf: string) => {
  localStorage.setItem(cpfKey, cpf)
}

export const setCode = (code_temp: string) => {
  localStorage.setItem(code, code_temp)
}


export const clearLocalStorage = () => {
  localStorage.clear()
}
